<section class="ma-solar-using-area ma-section-tile">
    <div class="container ma-us-container">
        <div class="row text-center border-bottom pt-3">
            <h2>Aké poznáme typy fotovoltík?</h2>
        </div>
        <div class="row mt-4">
            <div class="col-auto ma-font-size-higher">
                <p>Poznáme tri bežné typy fotovoltických systémov: on-grid, off-grid a hybridné. Menej známy typ je pre bytové domy, inštaluje sa priamo na balkón. Poďme sa spoločne na to pozrieť.</p>
            </div>
        </div>
        <div class="row mt-4 justify-content-start">
            <div class="col-md-6 ma-font-size-higher">
                <h3 class="text-uppercase">On-grid - bez batérie</h3>
                <p>On-grid fotovoltické systémy sú pripojené k verejnej elektrickej sieti. 
                    To znamená, že môžu dodávať elektrinu do siete, keď je jej vyprodukovanej viac, ako spotrebujú, a naopak môžu čerpať elektrinu zo siete, keď potrebujú viac, ako vyprodukujú.</p>
            </div>
            <div class="col text-center picture-on-grid ma-image">
            </div>
        </div>
        <div class="row mt-4 justify-content-end">
            <div class="col text-center ma-image picture-off-grid mb-2">
            </div>
            <div class="col-md-6 ma-font-size-higher">
                <h3 class="text-uppercase">Off-grid - sieť iba na batériu</h3>
                <p>
                    Off-grid fotovoltické systémy sú nezávislé od verejnej elektrickej siete. 
                    To znamená, že musia uskladniť všetkú vyrobenú elektrinu do batérií a používať ju podľa potreby. 
                </p>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="col-md-6 ma-font-size-higher">
                <h3 class="text-uppercase">Hybrid - kombinácia, sieť aj batéria</h3>
                <p>
                    Hybridné fotovoltické systémy kombinujú vlastnosti on-grid a off-grid systémov. To znamená, že sú pripojené k sieti, ale zároveň majú aj batérie.
                </p>
            </div>
            <div class="col text-center picture-hybrid ma-image">
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="col text-center picture-balcon ma-image">
            </div>
            <div class="col-md-6 ma-font-size-higher">
                <h3 class="text-uppercase">Balkónový systém</h3>
                <p>Balkónový solárny systém je ideálnym riešením pre bytové domy, kde nemajú prístup na strechu. 
                    Skladá sa z jedného alebo viacerých solárnych panelov, ktoré sa umiestnia na balkón alebo terasu.
                </p>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="ma-contact-btn">
                <button title="Zistiť viac" [routerLink]="['/solar/typefve']" class="btn m-4"><i class="fa-solid fa-circle-arrow-right fa-sm"></i> Zistiť viac</button>
            </div>
        </div>
    </div>
</section>