import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EshopComponent } from './eshop.component';
import { LoginComponent } from './login/login.component';
import { EShopMainComponent } from './e-shop-main/e-shop-main.component';
import { RegisterUserComponent } from './register/registerUser.component';
import { AuthGuard } from './helpers/auth.guard';
import { PageWorkingComponent } from '../layout/page-working/page-working.component';

const routes: Routes = [
  {  
    path: '',
    component: PageWorkingComponent,
    /* component: EshopComponent, 
    children: [
      {
        path: '',
        loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) 
      },
      { path: 'login', component: LoginComponent},
      { path: 'register', component: RegisterUserComponent}
    ]*/
  } 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EShopRoutingModule { }
