import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SolarComponent } from './solar.component';
import { SolarMainComponent } from './solar-main/solar-main.component';
import { SolarHouseComponent } from './solar-house/solar-house.component';
import { SolarKeyComponent } from './solar-key/solar-key.component';
import { SolarCompanyComponent } from './solar-company/solar-company.component';
import { SolarIntroComponent } from './solar-intro/solar-intro.component';
import { SolarBatteryComponent } from './solar-battery/solar-battery.component';
import { SolarUsingComponent } from './solar-using/solar-using.component';
import { SolarDifferencesComponent } from './solar-differences/solar-differences.component';
import { SolarWhyBuyComponent } from './solar-why-buy/solar-why-buy.component';
import { SolarRoofComponent } from './solar-roof/solar-roof.component';
import { SolarCarPowerComponent } from './solar-car-power/solar-car-power.component';
import { PageWorkingComponent } from '../layout/page-working/page-working.component';

const routes: Routes = [
  {  
    path: '',
    component: SolarComponent,
    data: { breadcrumb: 'Fotovoltické systémy', url: 'solar' },
    children: [
      {
        path: '', component: SolarIntroComponent,
        /* data: { breadcrumb: 'Fotovoltické systémy', url: 'solar' } */
      },
      {
        path: 'key', component: SolarKeyComponent,
        data: { breadcrumb: 'Fotovoltické systémy na klúč' }
      },
      {
        path: 'battery', component: SolarBatteryComponent,
        data: { breadcrumb: 'Batérie' }
      },
      {
        path: 'company', component: SolarCompanyComponent,
        data: { breadcrumb: 'Fotovoltické systémy pre firmy' }
      },
      { 
        path: 'house', component: SolarHouseComponent,
        data: { breadcrumb: 'Predpripravené konfigurácie' }
      },
      { 
        path: 'typefve', component: SolarUsingComponent,
        data: { breadcrumb: 'Typy fotovoltík' }
      },
      { 
        path: 'solarRoof', component: SolarRoofComponent,
        data: { breadcrumb: 'Solárna strecha' }
      },
      { 
        path: 'solarUmbrella', component: PageWorkingComponent,
        data: { breadcrumb: 'Solárne slnečníky' }
      },
      { 
        path: 'solarBackpack', component: PageWorkingComponent,
        data: { breadcrumb: 'Solárne ruksaky' }
      },
      { 
        path: 'solarCamera', component: PageWorkingComponent,
        data: { breadcrumb: 'Solárne ruksaky' }
      },
      { 
        path: 'heaterPump', component: PageWorkingComponent,
        data: { breadcrumb: 'Tepelné čerpadlo' }
      },
      { 
        path: 'diff', component: SolarDifferencesComponent,
        data: { breadcrumb: 'Nie je panel ako panel' }
      },
      { 
        path: 'whyBuy', component: SolarWhyBuyComponent,
        data: { breadcrumb: 'Prečo je to dobrá investícia?' }
      },
      { 
        path: 'carEnergy', component: SolarCarPowerComponent,
        data: { breadcrumb: 'Solárne nabíjačky' }
      }
    ] 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SolarPanelRoutingModule { }
