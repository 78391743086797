import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-differences',
  templateUrl: './solar-differences.component.html',
  styleUrls: ['./solar-differences.component.less']
})
export class SolarDifferencesComponent {

}
