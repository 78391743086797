import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-why-buy',
  templateUrl: './solar-why-buy.component.html',
  styleUrls: ['./solar-why-buy.component.less']
})
export class SolarWhyBuyComponent {

}
