import { Component } from '@angular/core';

@Component({
  selector: 'app-zaffo-explain',
  templateUrl: './zaffo-explain.component.html',
  styleUrls: ['./zaffo-explain.component.less']
})
export class ZaffoExplainComponent {

}
