import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OroComponent } from './oro.component';
import { PageWorkingComponent } from '../layout/page-working/page-working.component';

const routes: Routes = [
  {  
    path: '',
    component: PageWorkingComponent,//OroComponent,
    data: { breadcrumb: 'Oro caffe' } 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OroCafeRoutingModule { }
