<section class="ma-clean-area ma-section-tile">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- <p>Delgrosso vznikla v roku 1951 a pod značkou Clean filters začala vyrábať filtre pre osobné, úžitkové a nákladné vozidlá.Sortiment predstavujú
                    olejové filtre, vzduchové filtre, palivové a peľové kabínové filtre pre všetky značky vozidiel.</p>
                 -->
                 <p>Clean filters je značka filtrov pre rôzne druhy vozidiel, ktorú vytvorila spoločnosť Delgrosso v roku 1951. 
                    Ponúka olejové, vzduchové, palivové a peľové kabínové filtre pre osobné, úžitkové a nákladné automobily všetkých značiek. Clean filters je zárukou kvality a spoľahlivosti.
                </p>    
            </div>
        </div>
        <app-gallery [type]=0 idCarousel="cleanCarousel"></app-gallery>
    </div>
</section>
