import { AboutComponent } from './about/about.component';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { MainComponent } from './main/main.component';
import { RentComponent } from './rent/rent.component';
import { ContactComponent } from './layout/contact/contact.component';
import { PageWorkingComponent } from './layout/page-working/page-working.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GdprComponent } from './layout/gdpr/gdpr.component';
import { CookiesEuComponent } from './layout/cookies-eu/cookies-eu.component';

const routes: Routes = [
{  
  path: '',
  component: MainComponent
},
{
  path: 'shop',
  //component: PageWorkingComponent
  loadChildren: () => import('../app/e-shop/e-shop.module').then(m => m.EShopModule) 
},
{
  path: 'solar',
  loadChildren: () => import('../app/solar-panel/solar-panel.module').then(m => m.SolarPanelModule) 
},
{
  path: 'oro',
  loadChildren: () => import('../app/oro-cafe/oro-cafe.module').then(m => m.OroCafeModule) 
},
{
  path: 'rent',
  component: RentComponent,
  data: { breadcrumb: 'Prenájom priestorov'} 
},
{
  path: 'web',
  loadChildren: () => import('../app/web-service/web-service.module').then(m => m.WebServiceModule) 
},
{
  path: 'about',
  component: AboutComponent,
  data: { breadcrumb: 'O nás' } 
},
{
  path: 'contact',
  component: ContactComponent,
  data: { breadcrumb: 'Kontakt' } 
},
{
  path: 'gdpr',
  component: GdprComponent,
  data: { breadcrumb: 'GDPR' } 
},
{
  path: 'zasady-eu',
  component: CookiesEuComponent,
  data: { breadcrumb: 'Zasady cookies (EU)'}
},
{ 
  path: '**', pathMatch: 'full',
  component: PageNotFoundComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: true,
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
