import { Component } from '@angular/core';

@Component({
  selector: 'app-page-working',
  templateUrl: './page-working.component.html',
  styleUrls: ['./page-working.component.less']
})
export class PageWorkingComponent {

}
