<section class="m-page-not-found-area">
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <img class="ma-image" src="../../../assets/images/meeting.webp">
            <p class="fs-3"><span class="text-danger">Opps!</span> Na stránke usilovne pracujeme <span class="text-danger">:(</span></p>
            <p class="lead">
                Ospravedlňujeme sa! Zrejme stránka na ktorú sa snažíš pristúpiť ešte nie je hotová, skús prosím neskôr. <br>Medzitým si pozri ostatné dostupné stránky.
            </p>
            <a href="#" class="btn ma-btn">Vezmeme ťa na úvodnú?</a>
        </div>
    </div>
</section>