import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-solar-benefits',
  templateUrl: './solar-benefits.component.html',
  styleUrls: ['./solar-benefits.component.less']
})
export class SolarBenefitsComponent {
  name = 'Angular';
  constructor(@Inject(PLATFORM_ID) platformId: Object){
    if (isPlatformBrowser(platformId)) {
      window.addEventListener("scroll", (event)=>{
        const box = document.querySelector('.ma-ben-container');
        if (box != null && (box.getBoundingClientRect().height - box.getBoundingClientRect().y >= 0)  ) {
          box.classList.add('container-anim');
        }
      });
    }
  }
}
