<section class="ma-solar-key-area ma-section-tile">
    <div class="container">
        <div class="row text-center mt-3">
            <h2 class="text-uppercase">Fotovoltika na kľúč</h2>
            <span class="text-lighter">Celkové riešenie pre Vašu domácnosť</span>
        </div>
        <div class="row mt-4 justify-content-around ma-attribute shadow">
            <div class="col-md-4 border-end h-100">
                <p>Fotovoltika na klúč je riešenie fotovoltického systému, ktoré zahŕňa kompletné služby od obhliadky domu až po inštaláciu. 
                    Zákazník získa fotovoltický systém na mieru a nemusí sa starať o žiadne ďalšie detaily.</p>
            </div>
            <div class="col-md-4 border-end">
                <p>Inštalácia fotovoltického systému môže byť pre Vás komplikovaný proces, ktorý vyžaduje administratívne úkony, vedomosti a prax pre zapojenie celého systému.
                    Preto ponúkame túto službu, aby sme ušetrili Váš čas a nervy s byrokraciou okolo toho.
                </p>
            </div>
            <div class="col-md-4">
                <p>K fotovoltickému systému máte na výber z jedno-fázového alebo troj-fázového invertora. 
                    Avšak pri vysokých výkonoch sa odporúča používať troj-fázový invertor.
                </p>
            </div>
        </div>
        <div class="row mt-4 pb-4 align-items-center">
            <div class="col text-center ma-flex-auto">
                <img class="rounded float-start img-thumbnail" width="100%" height="100%" src="../../../assets/images/solar/boss_male.webp" alt="Montér pre fotovoltické panely">
            </div>
            <div class="col ma-box-timeline mt-md-0 mt-2">
                <div class="timeline-steps aos-init aos-animate" data-aos="fade-up">
                    <!-- <div class="timeline-step">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="oslovíte nás?" data-original-title="prvý kontakt">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">prvý kontakt</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">oslovíte nás</p>
                        </div>
                    </div> -->
                    <div class="timeline-step">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="chceme sa stretnúť" data-original-title="druhý kontakt">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">oslovenie a dohodnutie stretnutia</p>
                            <!-- <p class="h6 text-muted mb-0 mb-lg-0">navrhneme stretnutie</p> -->
                        </div>
                    </div>
                    <div class="timeline-step">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="u vás na káve" data-original-title="u Vás">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">u Vás</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">obhliadka a poviete nám Vašu predstavu</p>
                        </div>
                    </div>
                    <div class="timeline-step">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="spoločne to zvládneme" data-original-title="spoločnými silami">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">spoločnými silami</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">spoločne nájdeme riešenie s ktorým budete spokojný a podáme dotáciu</p>
                        </div>
                    </div>
                    <!-- <div class="timeline-step mb-0">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="existuje dotácia?" data-original-title="dotácia">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">dotácia</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">podáme dotáciu (ak to bude možné)</p>
                        </div>
                    </div> -->
                    <div class="timeline-step mb-0">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="dovezieme, zamontujeme a ukážeme" data-original-title="dovezenie a montáž">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">dovezenie a montáž</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">pustíme sa do montáže fotovoltiky</p>
                        </div>
                    </div>
                    <div class="timeline-step mb-0">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="pome to hromadu energie vypustiť" data-original-title="oživenie">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">oživenie</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">po úspešnom namontovaný, prejdeme na oživenie systému</p>
                        </div>
                    </div>
                    <div class="timeline-step mb-0">
                        <div class="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" 
                        data-content="pri dobrej káve sa rozlúčime" data-original-title="oživenie">
                            <div class="inner-circle"></div>
                            <p class="h6 mt-3 mb-1">rozlúčenie</p>
                            <p class="h6 text-muted mb-0 mb-lg-0">rozlúčime sa s dobrým feedbackom</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>