import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainComponent } from './main.component';
import { CleanComponent } from './clean/clean.component';
import { ZaraComponent } from './zara/zara.component';
import { PowerfulComponent } from './powerful/powerful.component';
import { ZaffoComponent } from './zaffo/zaffo.component';
import { GalleryComponent } from './gallery/gallery.component';
import { BannerComponent } from './banner/banner.component';
import { ZaffoExplainComponent } from './zaffo/zaffo-explain/zaffo-explain.component';

@NgModule({
  declarations: [
    MainComponent,
    CleanComponent,
    ZaraComponent,
    PowerfulComponent,
    ZaffoComponent,
    GalleryComponent,
    BannerComponent,
    ZaffoExplainComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MainComponent
  ]
})
export class MainModule { }
