<button id="cookiesBtn" title="Cookies" type="button" class="ma-btn btn btn-floating border border-danger border-opacity-25" data-bs-toggle="modal" *ngIf="getCookie()" data-bs-target="#cookieModal">
    <i class="fa-solid fa-triangle-exclamation"></i>
</button>

<div class="modal fade" id="cookieModal" tabindex="-1" aria-labelledby="cookieModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content container">
          <div class="modal-body">
            <div class="row justify-content-between align-items-center ">
                <div class="col-md-auto">
                   <img src="https://i.imgur.com/Tl8ZBUe.png" width="40">
                </div>
                <div class="col">
                    <p>S cieľom zabezpečiť riadne fungovanie tejto webovej lokality ukladáme niekedy na Vašom zariadení malé dátové súbory, tzv. cookie. 
                      Tieto súbory nám umožňujú zapamätať si Vaše nastavenia. 
                      S používaním tejto webovej lokality súhlasíte s používaním cookie.
                    </p>
                </div>
            </div>
            <div class="row">
              <a aria-label="Zásady cookies" data-bs-dismiss="modal" [routerLink]="['zasady-eu']">Zásady cookies</a>
          </div>
          </div>
          <div class="modal-footer justify-content-between">
                <div class="col-auto">
                    <button id="moreInfoCookiesBtn" title="Zisti viac gdpr" class="btn btn-primary text-white" data-bs-target="#cookieModalInfoModal" data-bs-toggle="modal">Zistiť viac o GDPR<i class="fa fa-angle-right ml-2"></i></button>
                </div>
                <div class="col-auto">
                  <button id="agreeBtn" title="Súhlasím" class="btn btn-success me-1" type="button" data-bs-dismiss="modal" (click)="setCookie()">Súhlasím</button>
                  <button id="disagreeBtn" title="Nesúhlasím" class="btn btn-danger" type="button" data-bs-dismiss="modal" (click)="deleteCookie()">Nesúhlasím</button>
              </div>
           </div>
        </div>
    </div>
</div>

<div class="modal fade" id="cookieModalInfoModal" aria-hidden="true" aria-labelledby="cookieModalInfo" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-second-content modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="cookieModalInfoH1">Zásady spracovania a ochrany osobných údajov</h1>
          <button id="closeCookiesBtn" title="Zavrieť" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <app-gdpr></app-gdpr>
        </div>
        <div class="modal-footer">
          <button id="cookiesBackBtn" title="Späť" class="btn btn-primary text-white" data-bs-target="#cookieModal" data-bs-toggle="modal">Späť</button>
        </div>
      </div>
    </div>
</div>