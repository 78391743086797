<section class="ma-solar-benefits-area ma-section-tile">
    <div class="container ma-ben-container">
        <div class="row text-center border-bottom">
            <h2 class="text-uppercase">Výhody fotovoltických systémov</h2>
            <span class="text-lighter">Základné výhody fotovoltických systémov na jednej stráne</span>
        </div>
        <div class="row mt-4">
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-plug-circle-bolt me-3"></i>Nižšie náklady na elektrinu</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Vlastná fotovoltická elektráreň Vám vie znížiť náklady aj o 80%. Pri dostatku priestoru a dobre navrhnutom systéme sa nemusíte báť nedostatku financií, slnko Vám faktúru nepošle ;) </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-leaf me-3"></i>Ekologickejšie to už nebude</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Pri výrobe elektriny z fotovoltického panela vzniká o 90% menej CO2. Solárna energia sa môže rovnať aj palivovej</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-warehouse me-3"></i>Veľkoobchod</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Pre záujemcov o spoluprácu sme otvorený. Nemáme problém spolupracovať s rôznymi firmami po celom svete za veľkoobchodné ceny, ktoré majú rovnaký cieľ ako my spraviť tento svet viac ekologický a čistejší pre ďalšie generácie</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-plug-circle-exclamation me-3"></i>Obmedzenie výpadkov elektriny</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Preruší sa vedenie kvôli búrke alebo inej katastrofe a vy nechcete ostať bez elektriny? Nemajte hlavu v smútku, vďaka vlastným záložným batériám sa Vám to nestane. Systém automaticky zistí výpadok elektriny a prepne na odber z batérii.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-money-bill-trend-up me-3"></i>Akcia s dotáciami alebo bez</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Ponúkame Vám zľavu vo forme dotácii. Aktiálne štát nedáva dotácie na fotovoltiku a preto sme sa rozhodli, že zľavu Vám poskytneme.
                        </p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="ma-attribute ma-benefit-item shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="fa-solid fa-house-flood-water me-3"></i>Kombinácia s tepelným čerpadlom</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Vedeli ste, že fotovoltické panely sa najviac oplatia spolu s tepelným čerpadlom? Nie, nikto Vám to ešte nepovedal? My s tým nemáme problém, poradíme Vám ako ešte viac ušetriť a zabezpečíme tiež tepelné čerpadlo podľa potreby</p>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>