<app-breadcrumb></app-breadcrumb>
<section class="ma-rent-area ma-section-tile">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col text-center">
                <h4>Firma Sjada s.r.o. už niekoľko rokov prenajíma priestory na Mostárenskej ulici v Brezne.</h4>
                <p>Ak hľadáte prenájom haly, máme pre vás skvelú ponuku.</p>
            </div>
        </div>
        <div class="row mt-4 justify-content-around">
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-solid fa-warehouse me-3"></i>Veľkolepý priestor</h4>
                    </div>
                    <div class="item-content">
                        <p class="ma-attribute-text">Naša hala má veľa priestoru pre rôzne potreby. Môžete ju využiť na rôzne účely, ako napríklad skladovanie, výrobu, šport. Prenájom je výhodný a flexibilný, ponúkame rôzne zľavy a bonusy.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-solid fa-dungeon me-3"></i>Samostatný vstup</h4>
                    </div>
                    <div class="item-content">
                        <p class="ma-attribute-text">Ponúkame prenájom haly, ktorá je rozdelená do viacerých miestností. Každá miestnosť má vlastný samostatný vchod.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-regular fa-money-bill-1 me-3"></i>Prijateľná cena</h4>
                    </div>
                    <div class="item-content">
                        <p class="ma-attribute-text">Prenajmite si halu v priestrannom areáli s dobrou dopravnou dostupnosťou a bezpečnosťou. A to všetko za prijateľnú cenu, ktorá sa zmestí do vášho rozpočtu.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>