import { Component, Input } from '@angular/core';
import { Breadcrumb } from '../../models/breadcrumb';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less']
})
export class BreadcrumbComponent {
  @Input() background: string = "../../../assets/images/banner.webp";
  breadcrumbs$: Observable<Breadcrumb[]>;
  title: string;
  
  constructor(breadcrumbService: BreadcrumbService) {
    this.title = "";
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
    breadcrumbService.breadcrumbs$.subscribe(data => {
      if(data.length > 0){
        this.title = data[data.length - 1].displayName;
      }
    });
  }
}
