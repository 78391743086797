<section class="ma-solar-house-area ma-section-tile">
    <div class="container">
        <div class="row text-center">
            <h2 class="text-uppercase mt-3">Výhody fotovoltických systémov</h2>
            <span class="text-lighter">Predpripravené balíky systémov za prijateľné ceny</span>
        </div>
        <div class="row mt-4">
            <div class="col-md-4" data-toggle="tooltip" title="Klik">
                <div class="ma-content-offer card-flip ">
                    <div class="card-front">
                        <div class="ma-head-offer">
                            <div class="ma-head-content ">
                                <div class="ma-head-bg"></div>
                                <div class="head">
                                    <span>Základ | 3,3 kWp</span>
                                </div>
                            </div>
                        </div> 
                        <div class="ma-feature-list">
                            <ul>
                                <li><span>6x fotovoltických panelov 550Wp <br>(počet sa môže líšiť podľa dohody)</span></li>
                                <li><span>Jednofázový alebo trojfázový invertor<br> DC => AC 3,5kW</span></li>
                                <li><span>Konštrukcia na strechu</span></li>
                                <li><span>Kabeláž pre AC aj DC napätie</span></li>
                                <li><span>Istenie pre AC aj DC</span></li>
                                <li><span>Montáž, oživenie, test (voliteľné)</span></li>
                                <li><span>Pripojenie do distribučnej siete (voliteľné)</span></li>
                                <li><span>Revízia (voliteľné)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4" data-toggle="tooltip" title="Klik">
                <div class="ma-content-offer card-flip active"> <!-- active ak stale aktivne -->
                    <div class="card-front">
                        <div class="ma-head-offer">
                            <div class="ma-head-content ">
                                <div class="ma-head-bg"></div>
                                <div class="head">
                                    <span>Optimálna | 5,5 kWp</span>
                                </div>
                            </div>
                        </div> 
                        <div class="ma-feature-list">
                            <ul>
                                <li><span>10x fotovoltických panelov 550Wp <br>(počet sa môže líšiť podľa dohody)</span></li>
                                <li><span>Jednofázový alebo trojfázový invertor<br>DC => AC 6kW</span></li>
                                <li><span>Konštrukcia na strechu</span></li>
                                <li><span>Kabeláž pre AC aj DC napätie</span></li>
                                <li><span>Istenie pre AC aj DC</span></li>
                                <li><span>Montáž, oživenie, test (voliteľné)</span></li>
                                <li><span>Pripojenie do distribučnej siete (voliteľné)</span></li>
                                <li><span>Revízia (voliteľné)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4" data-toggle="tooltip" title="Klik">
                <div class="ma-content-offer card-flip ">
                    <div>
                        <div class="ma-head-offer">
                            <div class="ma-head-content ">
                                <div class="ma-head-bg"></div>
                                <div class="head">
                                    <span>Výkonna | 9,9kWp</span>
                                </div>
                            </div>
                        </div> 
                        <div class="ma-feature-list">
                            <ul>
                                <li><span>18x fotovoltických panelov 550Wp <br>(počet sa môže líšiť podľa dohody)</span></li>
                                <li><span>Trojfázový invertor DC => AC 10kW</span></li>
                                <li><span>Konštrukcia na strechu</span></li>
                                <li><span>Kabeláž pre AC aj DC napätie</span></li>
                                <li><span>Istenie pre AC aj DC</span></li>
                                <li><span>Montáž, oživenie, test (voliteľné)</span></li>
                                <li><span>Pripojenie do distribučnej siete (voliteľné)</span></li>
                                <li><span>Revízia (voliteľné)</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div class="row mt-4">
            <div class="col text-center">
                <h3>Kontaktujte nás a radi Vám poradíme a vypracujeme nezáväznú ponuku</h3>
                <p>Nechajte si svietiť slnko do domu aj do peňaženky!</p>
                <div class="ma-contact-btn">
                    <button id="solarContactBtn" title="Kontaktovať" [routerLink]="['/contact']" class="btn mb-4" (click)="setGlobalVar()">Kontaktovať</button>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col text-center">
                <h3>V ponúke máme rôzne doplnkové služby, viac info pri cenovej ponuke</h3>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col info">
                <p>*výkonnosť, počet, typ a výrobca panelov a invertorov sa môže meniť podľa požiadaviek zákazníka alebo dostupnosti tovaru</p>
            </div>
        </div>
    </div>
</section>