<app-banner></app-banner>
<div class="ma-main">
    <app-clean></app-clean>
    <app-zara></app-zara>
    <!-- <app-powerful></app-powerful> -->
    <app-zaffo></app-zaffo>
</div>

<div class="ma-new-product" *ngIf="offerShow" >
    <a aria-label="Fotovoltika" href="#/solar">
        <div class="container">
            <div class="row-sm">
                <a class="ma-row-sm-img">
                    <span class="ma-offer-img"></span>
                </a>
                <a class="ma-row">
                    <h1 class="text-white">Fotovoltické<br>panely teraz<br>v predaji!</h1>
                </a>
                <a class="ma-row">
                    <p class="text-white">Nezmeškajte<br>letnú zľavu až -20%</p>
                </a>
                <a class="ma-row">
                    <span class="ma-offer-img"></span>
                </a>
                <div class="ma-row mt-2 mt-md-3">
                    <a class="btn ma-btn" id="moreInfoBtn" title="Viac tu" >Viac tu</a>
                </div>
                
            </div>
        </div>
    </a>
    <button title="zavrieť" type="button" class="close" aria-label="Close" (click)="closeOffer()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>