<header class="ma-header-area">
    <div class="container">
        <div class="btn btn-vertical" (click)="verticalBtn()" [ngClass]="clickMenu ? 'click' : ''">
            <span class="fas fa-bars justify-content-center d-flex"></span>
         </div>
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a class="navbar-brand text-white me-0" [routerLinkActive]="['active']" [routerLink]="['']" aria-label="Hlavna stránka">
                   <!--  <a class="navbar-brand text-white me-0 d-flex text-center flex-column"> -->
                    <img src="./../../../assets/images/logo.webp" alt="logo firmy" height="70" width="80" class="d-inline-block align-text-top ma-logo">
                    <!-- <span style="font-size: 0.8rem;">Sjada</span> -->
                </a>
                <a class="navbar-brand text-white" [routerLinkActive]="['active']" [routerLink]="['']" aria-label="Hlavna stránka">
                    <span>Sjada</span>
                </a>
                <button id="collapseHeaderBtn" title="Zavrieť menu" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="col nav navbar-nav menu_nav mr-auto">
                        <li class="nav-item">
                            <a class="nav-link" aria-label="Hlavna stránka" [routerLinkActive]="['active']" [routerLink]="['']" data-bs-target="#navbarNavDropdown">Hlavná stránka</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-label="e-shop" [routerLinkActive]="['active']" [routerLink]="['shop']" data-bs-target="#navbarNavDropdown">e-shop</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-label="Fotovoltické systémy" [routerLink]="['solar']" data-bs-target="#navbarNavDropdown">Fotovoltické systémy</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-label="O nás" [routerLinkActive]="['active']" [routerLink]="['about']" data-bs-target="#navbarNavDropdown">O nás</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" aria-label="Kontakt" [routerLinkActive]="['active']" [routerLink]="['contact']" data-bs-target="#navbarNavDropdown">Kontakt</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" aria-label="Dalšie služby" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Dalšie služby
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li class="dropdown-item-li">
                                    <a class="dropdown-item" aria-label="Prenájom priestorov" [routerLinkActive]="['active']" [routerLink]="['rent']" data-bs-target="#navbarNavDropdown">Prenájom priestorov</a>
                                </li>
                                <li class="dropdown-item-li">
                                    <a class="dropdown-item" aria-label="Oro caffe" [routerLinkActive]="['active']" [routerLink]="['oro']" data-bs-target="#navbarNavDropdown">Oro caffe</a>
                                </li>
                                <li class="dropdown-item-li">
                                    <a class="dropdown-item" aria-label="Webové služby" [routerLinkActive]="['active']" [routerLink]="['web']" data-bs-target="#navbarNavDropdown">Webové služby</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</header>