<section class="ma-solar-battery-area ma-section-tile">
    <div class="container">
        <div class="row text-center border-bottom pt-3 pb-2">
            <h2 class="text-uppercase">Možnosti uskladnenia prebytočnej energie</h2>
            <span class="text-lighter">Záložná batéria vs virtuálna batéria</span>
        </div>
        <div class="row ma-phys-battery justify-content-center mt-1">
            <div class="row mt-2 justify-content-around">
                <h2 class="text-black">Záložná batéria</h2>
            </div>
            <div class="row mt-2 justify-content-around">
                <div class="col-md-4">
                    <div class="ma-attribute shadow">
                        <p>
                            Záložná batéria je zariadenie, ktoré sa inštaluje priamo na mieste výroby solárnej energie.
                            Má väčšiu kapacitu ako virtuálna batéria a nevyžaduje žiadne distribučné poplatky ani paušálne platby. 
                            Na druhej strane má záložná batéria vyššiu obstarávaciu cenu, potrebuje údržbu a má obmedzenú životnosť. 
                            Môže sa vyplatiť pre tých, ktorí chcú mať väčšiu kontrolu nad svojou solárnou energiou a nezávislosť od dodávateľov elektriny. 
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="ma-attribute shadow ma-sm-mtop">
                        <p>
                            Záložná batéria funguje tak, že ukladá prebytočnú solárnu energiu, ktorú vyrobili Vaše solárne panely, a neskôr ju používa na napájanie Vašich spotrebičov. 
                            Solárne panely vyrábajú elektrinu vo forme priameho prúdu (DC), ktorý sa môže ukladať do batérie alebo prevádzať na striedavý prúd (AC) pre domácnosť alebo distribučnú sieť. 
                            Batéria môže dodávať elektrinu, keď solárne panely nevytvoria dosť alebo počas výpadku elektriny.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mt-4 justify-content-around">
                <div class="col-lg-4 col-md-4 mb-3">
                    <div class="ma-attribute shadow text-start">
                        <div class="item-head">
                            <h4 class="ma-attribute-label"><span class="ma-attribute-icon fa-solid fa-thumbs-up me-3"></span>Výhody</h4>
                        </div>
                        <div class="item-content mt-2">
                            <ul>
                                <li>umožňuje fyzické uskladnenie a neskôr využitie solárnej energie bez distribučných poplatkov</li>
                                <li>umožňuje vytvoriť si zálohu pre prípad výpadku elektriny.</li>
                                <li>zvyšuje nezávislosť od dodávateľov elektriny a umožňuje lepšie hospodáriť s vyrobenou zelenou energiou</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 mb-3">
                    <div class="ma-attribute shadow text-start">
                        <div class="item-head">
                            <h4 class="ma-attribute-label"><span class="ma-attribute-icon fa-solid fa-thumbs-down me-3"></span>Nevýhody</h4>
                        </div>
                        <div class="item-content mt-2">
                            <ul>
                                <li>vyžaduje pomerne vysokú počiatočnú investíciu do batérie</li>
                                <li>vyžaduje väčší priestor na umiestnenie batérie</li>
                                <li>má obmedzenú životnosť</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row ma-virtual-battery justify-content-center mt-4">
            <div class="row mt-2 justify-content-around">
                <h2>Virtuálna batéria</h2>
            </div>
            <div class="row mt-2 justify-content-around">
                <div class="col-lg-4 col-md-4">
                    <div class="ma-attribute shadow">
                        <p>
                            Virtuálna batéria je služba, ktorú ponúkajú niektorí dodávatelia elektriny pre majiteľov domácich solárnych elektrární. 
                            Umožňuje Vám ukladať prebytočnú elektrinu, ktorú nevyužijete, do distribučnej siete a neskôr ju znova využiť, keď bude potrebná. 
                            Tým sa zvyšuje efektivita využitia solárnej energie a znižujú sa náklady na elektrinu. 
                            <br>Virtuálna batéria funguje na princípe vzájomného zápočtu dodanej a odobranej elektriny v rámci určitého obdobia (napr. mesiac, rok). 
                        </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="ma-attribute shadow ma-sm-mtop">
                        <p>V domácnostiach je najväčšia spotreba elektriny, keď sú všetci členovia doma. Spravidla to býva ráno a večer. 
                            Fotovoltický systém vyrába najviac energie počas dňa, pri dopade slnečných lúčov.
                            Ráno, keď budete doma to nerobí žiaden problém. Budete odoberať Vašu vyrobenú energiu ale večer po západe slnka sa systém automaticky prepne a začne odoberať energiu z virtuálnej batérie.
                            Takto šetrite až kým si energiu vo virtuálnej batérii neminiete.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mt-4 justify-content-around">
                <div class="col-lg-4 col-md-4 mb-3">
                    <div class="ma-attribute shadow text-start">
                        <div class="item-head">
                            <h4 class="ma-attribute-label"><span class="ma-attribute-icon fa-solid fa-thumbs-up me-3"></span>Výhody</h4>
                        </div>
                        <div class="item-content mt-2">
                            <ul>
                                <li>rýchle zriadenie</li>
                                <li>nie je potrebná žiadna fyzická inštalácia ani údržba batérie</li>
                                <li>nie je potrebná žiadna vstupná investícia do batérie</li>
                                <li>umožňuje ukladať prebytočnú solárnu energiu do distribučnej siete a neskôr ju znova využiť</li>
                                <li>zvyšuje efektivitu využitia solárnej energie a znižuje náklady na elektrinu</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 mb-3">
                    <div class="ma-attribute shadow text-start">
                        <div class="item-head">
                            <h4 class="ma-attribute-label"><span class="ma-attribute-icon fa-solid fa-thumbs-down me-3"></span>Nevýhody</h4>
                        </div>
                        <div class="item-content mt-2">
                            <ul>
                                <li>pri využívaní uloženej energie sa platia distribučné poplatky, ktoré tvoria väčšinu ceny elektriny</li>
                                <li>uložená energia sa spája s ostatnou energiou v sieti a nie je možné ju použiť ako zálohu pri výpadku elektriny</li>
                                <li>dodávatelia elektriny profitujú z prebytočnej solárnej energie</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>