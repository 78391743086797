import { Component, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { EmailService } from "../../services/email.service";
import { Subject } from 'src/app/models/enums';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less']
})
export class ContactFormComponent {
  @Input()
  get subject(): string { 
    return this._subject; 
  }
  set subject(value: string){
    this._subject = value;
  }

  @Input() 
  set sendEmailFrom(value: Subject) {
    this._sendEmailFrom = value;
    //this._sendTitle = value.length > 1 ? value[1] : "";
  }

  @Input() 
  get isMobile(): boolean {
    return this._isMobile;
  }
  set isMobile(value: boolean) {
    this._isMobile = value;
    this.form.controls.phone.setValidators([Validators.required, Validators.minLength(5)]);
  }

  private myId: string = "sjada-banner";
  private _sendTitle: string = '';
  private _sendEmailFrom: Subject = Subject.Kontakt;
  private _isMobile = false;
  private _subject = '';

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    message: new FormControl('', Validators.required),
    cookies: new FormControl('', Validators.required)
  });

  constructor(public http: EmailService, private cookies: CookieService) {}
  
  get f(){
    return this.form.controls;
  }

  submit(){
    //console.log(this.form.value);
    let user = {
      name: this.form.controls.name.value,
      email: this.form.controls.email.value,
      sendEmailFrom: this._sendEmailFrom,
      message: this.form.controls.message.value,
      phone: this.form.controls.phone.value,
      subject: this._subject,
      cookies: this.form.controls.cookies.value
    };
    let isExists = this.cookies.get(this.myId);
    if(isExists == null || isExists == ""){
      this.cookies.set(this.myId, "allow", 5);
    }
    this.http.sendEmail("sendEmail.php", user).subscribe(
      data => {
        let res:any = data; 
        console.log(
          `👏 > 👏 > 👏 > 👏 ${user.name} tvoj e-mail bol úspešne odoslaný!`
        );
        this.form.reset();
      },
      err => {
        console.log(err);
      },() => {
        //console.log("Hotovo");
      }
    );
  }
}
