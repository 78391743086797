import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.less']
})
export class CookiesComponent {
  private myId: string = "sjada-banner";
  constructor(private cookies: CookieService){
  }

  setCookie(){
    this.cookies.set(this.myId, "allow");
  }
  
  deleteCookie(){
    this.cookies.deleteAll();
  }

  getCookie() {
    let isExists = this.cookies.get(this.myId);
    if(isExists != null && isExists != ""){
      return false;
    }
    return true;
  }

  private getUniqueId(parts: number): string {
    const stringArr = [];
    for(let i = 0; i< parts; i++){
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}
