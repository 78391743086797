import { isPlatformBrowser } from '@angular/common';
import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.less']
})
export class SolarComponent {
  title: string = "Fotovoltické panely";
  ngOnInit(): void {
    this.metaTagService.updateTag(
      { name: 'title', content: 'Fotovoltické systémy za bezkonkurenčné ceny' }, "name='title'");
    this.titleService.setTitle(this.title);
    
    /* this.metaTagService.updateTag(
      { name: 'description', content: 'Add car template' }
    ); */
  }

  constructor(private titleService: Title, private metaTagService: Meta){}
}
