<app-breadcrumb></app-breadcrumb>   <!-- alebo pod section -->
<section class="ma-contact-area">
    <div class="ma-bg-container"></div>
    <div class="container ma-section-tile">
        <div class="row mt-4 justify-content-center">
            <div class="col-lg-11">
                <div class="row justify-content-between">
                    <div class="col-lg-6 d-flex align-items-stretch">
                        <div class="ma-info-wrap w-100 p-md-5 p-4">
                            <h3 class="mb-4 text-black">Kontaktujte nás</h3>
                            <div class="ma-div-box mb-3 d-flex align-items-start">
                                <div class="ma-icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-map-marker"></span>
                                </div>
                                <div class="ps-4">
                                    <p><span>Sjada <span class="d-inline text-lowercase">s.r.o.</span></span><a aria-label="Mapa" target="_blank" href="https://www.google.com/maps/place/Autodiely+Sjada/@48.7463945,19.6485365,756m/data=!3m1!1e3!4m15!1m8!3m7!1s0x4715640f24b68cab:0x5fe7672976e1ad03!2sAutodiely+Sjada!8m2!3d48.7463945!4d19.6507252!10e5!16s%2Fg%2F12mkvpj94!3m5!1s0x4715640f24b68cab:0x5fe7672976e1ad03!8m2!3d48.7463945!4d19.6507252!16s%2Fg%2F12mkvpj94?entry=ttu">Závodie 18/20, Čierny Balog 976 52</a></p>
                                    <p class="mt-2"><span class="d-inline">IČO: 31 596 720</span><span class="d-md-inline ms-md-2">DIČ: 2020 454 491</span></p>
                                </div>
                            </div>
                            <div class="ma-div-box mb-3 d-flex align-items-start">
                                <div class="ma-icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-phone"></span>
                                </div>
                                <div class="ps-4">
                                    <p><span>Mobil:</span><a aria-label="+421905226666" href="tel://00421905226666">+421 905 226 666</a></p>
                                    <p class="mt-1"><span class="ma-fot-serv">Fotovoltické oddelenie:</span><a aria-label="+421902596666" class="col" href="tel://00421902596666">+421 902 596 666</a></p>
                                </div>
                            </div>
                            <div class="ma-div-box mb-3 d-flex align-items-start">
                                <div class="ma-icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-paper-plane"></span>
                                </div>
                                <div class="ps-4">
                                    <p><span>Email:</span> <a aria-label="sjada@sjada.sk" href="mailto:sjada@sjada.sk;solar@sjada.sk">sjada@sjada.sk</a></p>
                                </div>
                            </div>
                            <div class="ma-div-box mb-3 d-flex align-items-start">
                                <div class="ma-icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-globe"></span>
                                </div>
                                <div class="ps-4">
                                    <p><span>Webová stránka</span> <a aria-label="https://www.sjada.sk" href="https://www.sjada.sk">sjada.sk</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 align-self-center">
                        <div class="ma-contact-wrap w-100 p-md-5 p-4">
                            <h3 class="text-center mb-4 text-dark">Ozvite sa</h3>
                            <app-contact-form subject="{{ subject }}" [sendEmailFrom]="_sendEmailFrom"></app-contact-form>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <iframe name="mapa" title="google mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2768.2289944991335!2d19.648930984176367!3d48.74641658055258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715640f24b68cab%3A0x5fe7672976e1ad03!2sAutodiely%20Sjada!5e1!3m2!1ssk!2ssk!4v1690196307390!5m2!1ssk!2ssk" 
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</section>