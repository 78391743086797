import { Component, Input } from '@angular/core';
import { Subject } from 'src/app/models/enums';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent {
  private _subject: string = "contact";
  _sendEmailFrom: Subject = Subject.Kontakt;
  @Input()
  get subject(): string { 
    return this._subject; 
  }
  set subject(value: string){
    this._subject = value;
  }

  constructor(public sharedService: SharedServiceService<Subject>){
    var tmpSubject = this.sharedService.getGlobalVar();
    if(tmpSubject != null){
      this.subject = Subject[tmpSubject];
      //var theRemovedElement = tmpSubject.shift();
      this._sendEmailFrom = tmpSubject;
      //this.sharedService.clearGlobalVar();
    }
    else {
      this.subject = Subject[Subject.Kontakt];
      this._sendEmailFrom = Subject.Kontakt;
    }
  }
}
