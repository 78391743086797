import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare function buttonToTop():any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  showPhone: boolean = true;
  title = 'Sjada';
  platformId: Object;
  /* &reg; */
  ngOnInit(): void {
/*     this.metaTagService.addTags([
      { name: 'title', content: 'Nechajte si svietiť slnko do domu aj do peňaženky! Za bezkonkurenčné ceny | SJADA' },
      { name: 'keywords', content: 'fotovoltika, fotovoltaika, system, solarne systemy, fotovolticke systemy, solar, návrh a montaz fotovoltických panelov, tepelne cerpadlo, cerpadla, klimatizacia, lacne, autodiely, auto' },
      { name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' },
      { name: 'canonical', content: 'https://www.sjada.sk/' },
      { name: 'description', content: 'Fotovoltické systémy, tepelné čerpadlá,solárne kolektory pre rodinné domy a firemné budovy za bezkonkurenčné ceny. Nechajte si svietiť slnko do domu aj do peňaženky!' },
      { name: 'author', content: 'Sjada s.r.o.' },
      { name: 'image', content: '/assets/images/auto.jpg' },
      { name: 'twitter:title', content: '/assets/images/auto.jpg' },
      { name: 'twitter:description', content: '/assets/images/auto.jpg' },
      { name: 'twitter:url', content: 'https://sjada.sk' },
      { name: 'twitter:image:src', content: '/assets/images/auto.jpg' },
      { name: 'og:image', content: '/assets/images/auto.jpg' },
      { name: 'og:locale', content: 'sk_SK' },
      { name: 'og:locale:alternate', content: 'en_GB' },
      { name: 'og:type', content: '/assets/images/auto.jpg' },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: 'Nechajte si svietiť slnko do domu aj do peňaženky! Za bezkonkurenčné ceny | SJADA' },
      { name: 'og:description', content: 'Fotovoltické systémy, tepelné čerpadlá,solárne kolektory pre rodinné domy a firemné budovy za bezkonkurenčné ceny. Nechajte si svietiť slnko do domu aj do peňaženky!' },
      { name: 'og:url', content: 'https://www.sjada.sk/solar' },
      { name: 'og:site_name', content: 'SJADA' },
      { name: 'article:publisher', content: 'https://www.facebook.com/www.sjada.sk/solar' },
      { name: 'date', content: '2021-05-17', scheme: 'YYYY-MM-DD' },
      ]); */
    //this.titleService.setTitle(this.title);
    if (isPlatformBrowser(this.platformId)) {
      buttonToTop();
    }
    
    /* this.metaTagService.updateTag(
      { name: 'description', content: 'Add car template' }
    ); */
  }

  constructor(private router: Router, private titleService: Title, private metaTagService: Meta, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId;  
    if (isPlatformBrowser(this.platformId)) {
      const browserUrl = window.location;
      if(browserUrl.pathname != "/"){
        console.log("redirect to" + browserUrl.pathname)
        this.router.navigate([browserUrl.pathname]);
      }
    }
    if(this.router.url.includes("/shop")){
      this.showPhone = false;
    }
    else {
      this.showPhone = true;
    }
    const scriptElt = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElt, 'type', 'text/javascript');
    this.renderer.setAttribute(scriptElt, 'src', './assets/scripts/top-btn.js');
    this.renderer.appendChild(this.document.head, scriptElt);
  }

}
