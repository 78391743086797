import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb } from '../models/breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Get the url "/page-1/page-2"
        const root = this.router.routerState.snapshot.root;

        // Create empty breadcrumb array
        const breadcrumbs: Breadcrumb[] = [];
        const breadcrumb = {
          displayName: "Domov",
          url: ""
        };
        breadcrumbs.push(breadcrumb);
        // Make first call to addBreadcrumb function
        this.addBreadcrumb(root.firstChild!, [], breadcrumbs);

        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  buildDepth(urlPath: string[]) {
    var depthStr = '/' + urlPath.toString();
    return depthStr;
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[]
  ) {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
      let constituentFolders: string[] = new Array();
      constituentFolders = routeUrl;
      let displayNames = route.data['breadcrumb'];
        const breadcrumb = {
          displayName: displayNames,
          url: '/' + constituentFolders.join('/') //this.buildDepth(route.url.map((url) => url.path))
        };
        if(displayNames != "" && displayNames != undefined && !breadcrumbs.some(p => p.displayName == breadcrumb.displayName)) {
          breadcrumbs.push(breadcrumb);
          
        }
        this.addBreadcrumb(route.firstChild!, routeUrl, breadcrumbs);
    }
  }
}
