import { Component } from '@angular/core';

@Component({
  selector: 'app-cookies-eu',
  templateUrl: './cookies-eu.component.html',
  styleUrls: ['./cookies-eu.component.less']
})
export class CookiesEuComponent {

}
