import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService<T> {
  private showCart: boolean = false;
  private _tmpValue: any;

  setGlobalShowrtCart(isShow:boolean) {
    this.showCart = isShow;
  }

  getGlobalShowrtCart():boolean{
      return this.showCart;
  }

  setGlobalVar(value: T){
    this._tmpValue = value;
  }

  getGlobalVar() : T{
    return this._tmpValue;
  }

/*   clearGlobalVar(){
    this._tmpValue = new any;
  } */
}
