<section class="ma-solar-car-energy-area ma-section-tile">
    <div class="container ">
        <div class="row text-center border-bottom pt-3">
            <h2>Solárne nabíjačky</h2>
        </div>
        <div class="row mt-4 justify-content-around ma-attribute shadow">
            <div class="col-md-4 border-end h-100">
                <p>
                    Existujú rôzne typy nabíjačiek elektro áut podľa ich výkonu, rýchlosti dobíjania, konektivity a kompatibility s rôznymi modelmi elektromobilov.
                </p>
            </div>
            <div class="col-md-4 border-end">
                <p> 
                    AC nabíjačky: používajú striedavý prúd (AC) a majú nižší výkon a pomalšiu rýchlosť dobíjania. 
                    Sú vhodné pre domáce alebo verejné použitie, kde nie je potrebné rýchle dobíjanie.
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    DC nabíjačky: používajú rovný prúd (DC) a majú vyšší výkon a rýchlejšiu rýchlosť dobíjania. 
                    Sú vhodné pre diaľničné alebo komerčné použitie, kde je potrebné rýchle dobíjanie.
                </p>
            </div>
        </div>
        <div class="ma-solar-car-energy-container mt-3">
            <div class="row mt-4 justify-content-start">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Nástenne EV nabíjačky pre domácnosti</h3>
                    <p>
                        Ponúkame EV nabíjačky s rôznymi výkonmi a moderným dizajnom.<br>
                        Nástenné nabíjačky pre elektromobily sú praktickým a výhodným riešením pre domáce dobíjanie vášho elektromobilu. 
                        Sú malé a kompaktné zariadenia, ktoré sa pripájajú k vašej domácej elektrickej sieti a umožňujú vám nabíjať váš elektromobil rýchlejšie a lacnejšie ako na verejných dobíjacích staniciach. 
                        Nástenné nabíjačky sú tiež bezpečné a spoľahlivé, pretože sú chránené pred vodou a prachom a majú zabudované ochranné funkcie.
                    </p>
                </div>
                <div class="col text-center picture-wall-charger ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-end">
                <div class="col text-center ma-image picture-stand-charger mb-2">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Stojanové EV nabíjačky</h3>
                    <p>
                        Stojanové nabíjačky pre elektromobily sú vysokovýkonné a rýchle zariadenia, ktoré sa používajú na nabíjanie elektromobilov na cestách alebo na verejných miestach.
                        Môžu mať výkon až 170 kW a prúd až 125 A, čo umožňuje nabiť 80 % kapacity batérie za približne 20 minút. Stojanové nabíjačky sú tiež riadené a chránené pomocou zabudovanej elektroniky.
                        Oplatia sa hlavne pre firmy, obce a mestá, ktoré chcú vybudovať modernú zelenú infraštruktúru a prilákať nových investorov.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>