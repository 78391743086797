<section class="ma-gdpr-area">
    <div class="container">
        <div class="row mt-4 justify-content-center">
            <p>
                Ochrana osobných údajov je dôležitou súčasťou našej webovej stránky. Rešpektujeme Vaše práva na súkromie a zodpovedne spracúvame Vaše osobné údaje, ktoré nám poskytujete pri používaní našej webovej stránky. 
                V tejto časti Vám poskytneme informácie o tom, aké údaje zbierame, na aký účel ich používame, s kým ich zdieľame a aké máte možnosti ovplyvniť ich spracovanie. 
                Tieto informácie sú v súlade so zákonom č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej len „zákon o ochrane osobných údajov“) a nariadením Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov (ďalej len „GDPR“). 
                Ak máte akékoľvek otázky alebo požiadavky týkajúce sa ochrany Vašich osobných údajov, môžete nás kontaktovať na e-mailovej adrese <a aria-label="email" href="mailto:sjada@sjada.sk">sjada@sjada.sk</a>.
            </p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>Kto je správcom Vašich osobných údajov?</h4>
            <p>Správcom a zároveň spracovateľom je spoločnosť Sjada s.r.o.  IČO: 31 596 720, so sídlom Závodie 18/20, Čierny Balog 976 52, Slovenská republika, ktorá zhromažďuje, uchováva a ďalej spracúva Vaše osobné údaje pre výkon svojej podnikateľskej činnosti. V rámci spoločnosti Sjada s.r.o. sú osobné údaje sprístupnené len oprávneným zamestnancom, a to len v rozsahu nevyhnutne potrebnom na účely spracovania. V rámci spoločnosti Sjada s.r.o. kladieme veľký dôraz na bezpečnostnú politiku, mlčanlivosť našich zamestnancov, dodržiavanie vnútorných smerníc a výber vhodných zmluvných partnerov.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>Aké osobné údaje o Vás spracúvame?</h4>
            <p>Jedná sa o identifikačné (najmä meno, priezvisko, titul, IČ, DIČ) a kontaktné údaje (najmä kontaktná adresa, telefónne číslo, mailová adresa), ďalej údaje z našej vzájomnej komunikácie. Všetky spomenuté osobné údaje ste nám oznámili v rámci našej spolupráce. Osobné údaje sú spoločnosťou Sjada s.r.o. spracúvané ako manuálne, tak automatizovane.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>Dôvody spracovania osobných údajov</h4>
            <p>Osobné údaje spracúvame najmä z právneho dôvodu plnenia zmluvy. Ide o prípady, keď realizujeme zmluvný záväzok, vrátane súvisiacej obchodnej a finančnej komunikácie. Ďalej spracúvame osobné údaje v rámci plnenia právnych povinností vyplývajúcich zo zákona (vedenie účtovníctva, daňové odvody, mzdová a personálna agenda, poskytovanie súčinnosti štátnym orgánom na základe zákona). Z právneho hľadiska nášho oprávneného záujmu Vám ako našim zákazníkom zasielame newsletter. 
                V prípade, že nie ste naším zákazníkom a chcete od nás dostávať newslettre, robíme tak na základe Vášho súhlasu. 
                V prípade záujmu pracovať pre spoločnosť Sjada s.r.o. spracúvame osobné údaje na základe súhlasu poskytnutého na účely výberového konania a využívame tieto údaje na vyhodnotenie vhodného kandidáta a následnú korešpondenciu. 
                Webové stránky spoločnosti Sjada s.r.o. používajú „cookies“ len na základe Vášho predchádzajúceho výslovného súhlasu. 
                Cookies sú malé dátové súbory, ktoré sú uložené na Vašom pevnom disku pomocou Vášho prehliadača. 
                Uchovávajú určité informácie, ktoré Váš prehliadač môže preniesť spätne k nám pri Vašom ďalšom prístupe na webovú stránku spoločnosti Sjada s.r.o.. 
                Tieto súbory nám umožňujú analyzovať Vaše používanie webovej stránky spoločnosti Sjada s.r.o.. 
                Pri Vašom prvom vstupe na webovú stránku spoločnosti Sjada s.r.o. sa objaví banner so žiadosťou o Váš súhlas s použitím „cookies“.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>Doba uchovávania osobných údajov</h4>
            <p>Vaše osobné údaje uchovávame po dobu nevyhnutnú na zabezpečenie všetkých práv plynúcich zo zmluvy, t.j. minimálne po dobu zabezpečenia realizácie zmluvného vzťahu (vrátane doby úplného vyrovnania práv a povinností plynúcich zo zmluvy a súčasného preukázania histórie zmluvného vzťahu) a ďalej po dobu, počas ktorej je spoločnosť Sjada s.r.o. povinná uchovávať dané informácie, vychádzajúc z platnej legislatívy, alebo po dobu, na ktorú ste nám na spracovanie udelili súhlas.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>Je spracovanie Vašich osobných údajov dobrovoľné?</h4>
            <p>V prípade, že nie ste našim zákazníkom, spracovanie osobných údajov sa vždy deje na základe Vášho súhlasu. V ostatných prípadoch sa nejedná o dobrovoľné spracovanie osobných údajov a jeho zákonnosť je podložená vyššie uvedenými právnymi dôvodmi. </p>
        </div>
        <div class="row mt-4 justify-content-center">
            <p>Pre maximalizovanie úžitku solárnej energie prinášame bezkonkurenčné riešenie úschovy elektrickej energie.</p>
            <p>Vďaka batériám a meničom si ju jednoducho a automaticky uložíte na neskôr. Zvyšok nespotrebovanej energie sa potom pošle späť do siete.</p>
        </div>
    </div>
</section>