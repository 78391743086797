<section class="ma-zara-area ma-section-tile">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <p>
                    Marmittezara S.p.A. je spoločnosť, ktorá sa od roku 1960 špecializuje na výrobu kvalitných výfukových systémov pre všetky typy a značky osobných automobilov. V našom sklade nájdete širokú ponuku ich produktov, ktoré teraz ponúkame za výhodné ceny v rámci akcie vyprázdniť sklad. 
                </p>
                <p>
                    Nezmeškajte túto príležitosť a zaobstarajte si nový výfuk od Marmittezara S.p.A. ešte dnes!
                </p>
            </div>
        </div>
    </div>
</section>