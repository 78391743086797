<section class="ma-banner-area">
    <div class="ma-banner d-flex align-items-center">
        <div class="bg-banner"></div>
        <div class="container">
            <div class="row">
                <div class="ma-anim col banner-content text-center text-white">
                    <p class="ma-change-warehouse">Meníme sklady, meníme služby</p>
                    <h1>Firma Sjada s novým výzorom</h1>
                    <h2>Pre nový začiatok potrebujeme <a href="#/shop"><span class="text-uppercase text-danger fw-bold">vyprázdniť sklady!</span></a></h2>
                    <p>Menší úvod značiek, ktoré máme ihneď k odberu</p>
                </div>
            </div>
        </div>
    </div>
</section>