<section class="ma-solar-roof-area ma-section-tile">
    <div class="container ">
        <div class="row text-center border-bottom pt-3">
            <h2>Fotovotlické krytiny</h2>
        </div>
        <div class="row mt-4 justify-content-around ma-attribute shadow">
            <div class="col-md-4 border-end h-100">
                <p>
                    Fotovoltická krytina je navrhnutá tak, aby sa podobala na bežnú strešnú krytinu, čím sa zvyšuje estetika a hodnota vašej nehnuteľnosti. 
                    Fotovoltická krytina je tiež oveľa ľahšia a odolnejšia ako tradičné fotovoltické panely, čo znižuje náklady na inštaláciu a údržbu.
                </p>
            </div>
            <div class="col-md-4 border-end">
                <p> 
                    Je esteticky príťažlivejšia a môže sa ľahšie integrovať do architektúry budovy. 
                    Fotovoltickú krytinu môžete kombinovať s inými typmi strešnej krytiny, ako sú škridle alebo plechy. 
                    Fotovoltická škriďľa je tiež ľahšia a odolnejšia ako tradičné fotovoltické panely.
                </p>
            </div>
            <div class="col-md-4">
                <p>
                    Škriďľa má však nižšiu účinnosť a vyššiu cenu ako bežné fotovoltické panely.
                    Oplatí sa hlavne pri prerábany alebo stavaný nových striech, kde nemusíte kupovať zvlášť krytinu a fotovoltické panely.
                </p>
            </div>
        </div>
        <div class="ma-solar-roof-container mt-3">
            <div class="row mt-4 justify-content-start">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Všetko v jednom</h3>
                    <p>Na výmenu strechy potrebujete dosť peňazí, najmä ak chcete aj fotovoltiku. Táto krytina je riešením, ktoré Vám ušetrí energiu aj peniaze.</p>
                    <p>
                        Solárna škridľa nielen zabezpečuje vodotesnosť a tepelnú ochranu, ale aj vyrába elektrinu.
                        Znižuje potrebu budovy spoliehať sa na vonkajšie zdroje energie a umožňuje im dosiahnuť nulovú spotrebu energie.
                    </p>
                </div>
                <div class="col text-center picture-solar-roof ma-image">
                </div>
            </div>
        </div>
    </div>
</section>