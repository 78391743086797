import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EShopRoutingModule } from './e-shop-routing.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { StoreRepositoryService } from './services/store-repository.service';
import { EshopComponent } from './eshop.component';
import { EShopNavbarComponent } from './e-shop-navbar/e-shop-navbar.component';
import { LoginComponent } from './login/login.component';
import { fakeBackendProvider } from './helpers/fake-backend';
import { EShopMainComponent } from './e-shop-main/e-shop-main.component';
import { RegisterUserComponent } from './register/registerUser.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

@NgModule({
  declarations: [
    WelcomeComponent,
    EshopComponent,
    EShopNavbarComponent,
    LoginComponent,
    EShopMainComponent,
    RegisterUserComponent,
    LoginFormComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    EShopRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    StoreRepositoryService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider
  ]
})
export class EShopModule { }
