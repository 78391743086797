<footer class="ma-footer-area mt-auto section_gap">
    <div class="ma-border-line m-auto"></div>
    <div class="container">
        <!-- <div class="row justify-content-start">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="ma-footer-widget">
                    <h6 class="ma-footer-title">About</h6>
                    <p>Firma Sjada s.r.o. sa už niekoľko rokov orientuje na dovoz a distribúciu auto dielov na celom území Slovenska do predajní autosúčiastok a autoservisov.</p>
                </div>
            </div>					
             <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="ma-footer-widget">
                    <h6 class="ma-footer-title">Newsletter</h6>
                    <p>For business professionals caught between high OEM price and mediocre print and graphic output, </p>		
                    <div id="mc_embed_signup">
                        <form target="_blank" action="" method="get" class="subscribe_form relative">
                            <div class="input-group d-flex flex-row">
                                <input name="EMAIL" placeholder="Emailová adresa" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Naozaj nie?'" required="" type="email">
                                <button class="btn sub-btn"><span class="fa-regular fa-paper-plane"></span></button>		
                            </div>									
                            <div class="mt-10 info"></div>
                        </form>
                    </div>
                </div>
            </div>					
        </div> -->
        
        <div class="row ma-footer-bottom d-flex justify-content-between align-items-center">
            <p class="col-lg-8 col-sm-12 footer-text m-0">Copyright &copy;2023 All rights reserved | 
                <span class="fs-6  footer-text">Created by <a aria-label="web design email" class="" href="mailto:sjada@sjada.sk">Sjada web design</a></span></p>
            <div class="col-lg-4 col-sm-12 footer-social">
                <!-- <a href="#"><i class="fa fa-facebook"></i></a> -->
                <a aria-label="WhatsApp" href="https://wa.me/00421902596666" target="_blank"><i class="fa fa-whatsapp"></i></a>
                <!-- <a href="#" target="_blank"><i class="fa fa-twitter"></i></a> -->
                <!-- <a href="#"><i class="fa fa-instagram"></i></a> -->
            </div>
        </div>
    </div>
</footer>