import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
declare var header:any;
declare function headerActive():any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  showCart: boolean = false;
  clickMenu: boolean = false;
  platformId: Object;

  constructor(public sharedService: SharedServiceService<boolean>, private router: Router, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: Object){
    this.platformId = platformId;  
    if (isPlatformBrowser(this.platformId)) {
      router.events.subscribe((val) => {
        let navbar = $(".navbar");
        let btnVertical = $(".btn-vertical");
        if(this.router.url.includes("/shop")){
          this.sharedService.setGlobalShowrtCart(true);
          this.showCart = true;
          navbar.addClass("vertical");
          btnVertical.show();
        }
        else {
          this.sharedService.setGlobalShowrtCart(false);
          this.showCart = false;
          navbar.removeClass("vertical");
          btnVertical.hide();
          this.clickMenu = false;
          $('.navbar').removeClass("show");
        }
      });
    }
    const scriptElt = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElt, 'type', 'text/javascript');
    this.renderer.setAttribute(scriptElt, 'src', './assets/scripts/header.js');
    this.renderer.appendChild(this.document.head, scriptElt);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      console.log("ahoj");
      headerActive();
      header.fixedHeader();
    }
  }


  verticalBtn() {
    if(this.clickMenu){
      this.clickMenu = false;
    }
    else {
      this.clickMenu = true;
    }
    if (isPlatformBrowser(this.platformId)) {
      $('.navbar').toggleClass("show");
    }
  }
}
