<section class="ma-solar-using-area ma-section-tile">
    <div class="container ">
        <div class="row text-center border-bottom pt-3">
            <h2>Typy fotovoltík</h2>
        </div>
        <div class="row mt-4">
            <div class="col-auto ma-font-size-higher">
                <p>V súčasnosti sa fotovoltické systémy stávajú bežným štandardom technického vybavenia moderných domácností a firiem.
                    Fotovoltické systémy sú ekonomické, ekologické a nepýtajú faktúru ako distribútori za cenu energií, ktoré stále rastú kvôli tomu ich návratnosť je stále nižšia.</p>
            </div>
        </div>
        <div class="ma-us-container">
            <div class="row mt-4 justify-content-start">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">On-grid - bez batérie</h3>
                    <p>On-grid systém je typ solárneho systému, ktorý je pripojený k verejnej elektrickej sieti. 
                        To znamená, že solárne panely vyrábajú elektrinu počas dňa, ktorá sa buď spotrebuje v domácnosti, alebo sa pošle späť do siete. </p>
                    <p>Ideálna kombinácia s virtuálnou batériou je, kde sa prebytočná energia posiela spätne cez distribučnú sieť.
                        Keď je výroba menšia ako dopyt, solárna elektráreň čerpá elektrinu z virtuálnej batérie a po vyčerpaní zo siete.</p>
                    <h5>Výhody</h5>
                    <ul>
                        <li>nižšia investícia do solárnych panelov</li>
                        <li>možnosť využiť dotácie</li>
                        <li>nie je potrebná žiadna vstupná investícia do batérie</li>
                    </ul>
                    <h5>Nevýhody</h5>
                    <ul>
                        <li>závislosť na fungovaní verejnej siete</li>
                        <li>potreba platiť poplatky za pripojenie a prevádzku siete</li>
                    </ul>
                    <!-- <p>Fotovoltický systém typu On-Grid v stručnom preklade znamená že všetkú prebytočnú energiu, ktorú aktuálne nespotrebujete pošlete elektrárňam.
                        Je to aktuálne najlacnejšie riešenie na trhu, v ktorom je energia využita len cez deň keď slnečné lúče Vám dodávaju elektrinu.
                    </p>
                    <p>V prípade zakúpenia Virtuálnej batérie sa prebytočná energia posiela spätne cez distribučnú sieť do Vašej virtuálnej batérie, kde sa hromadí a čaka na využitie.
                        Využijete ju hneď ako Vaša domácnosť bude potrebovať energiu a fotovoltické panely už nebudú stíhať vyrábať, nemusíte sa o nič starať, všetko prebieha automaticky.
                    </p> -->
                </div>
                <div class="col text-center picture-on-grid ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-end">
                <div class="col text-center ma-image picture-off-grid mb-2">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Off-grid - sieť iba z batérie</h3>
                    <p>
                        Off-grid systém je systém, ktorý nevyužíva elektrickú sieť na zásobovanie elektrinou.
                        Vyrobená energia sa okamžite spotrebuje. Pre využitie cez noc potrebujete batériové články.
                        Off-grid je vhodný na miesta kde nemáte možnosť sa pripojiť k distribučnej sieti ako sú chaty, karavany, kontajnerové domy atď. Ale táto možnosť je stále k dispozícii.
                    </p>
                    <h5>Výhody</h5>
                    <ul>
                        <li>nezávislosť od dodávateľa elektriny a možných výpadkov siete</li>
                        <li>veľké úspory: ak žijete v odľahlej oblasti, kde je pripojenie k sieti drahé alebo nemožné</li>
                        <li>zníženiu emisií skleníkových plynov</li>
                    </ul>
                    <h5>Nevýhody</h5>
                    <ul>
                        <li>obmedzená kapacita a spoľahlivosť systému v závislosti od počasia a spotreby</li>
                        <li>ak chcete mať dostatok elektriny pre svoje potreby, musíte byť veľmi opatrní pri spotrebe</li>
                    </ul>
                    <!-- <p>Získaná energia z fotovoltických panelov je okamžite využívaná pre domácnosť a pri nevyužívany sa automaticky odpojí a laicky povedané prestane sa vyrábať. 
                        Pokiaľ by ste si chceli uchovať energiu aj na neskôr k tomu sú vhodné batériové články kde sa prebytočná energia ukladá. 
                        Naše systémy môžu ukladať energiu do Lithiových alebo olovených batérii.
                    </p> -->
                </div>
            </div>
            <div class="row mt-4 justify-content-center">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Hybrid - kombinácia, sieť aj batéria</h3>
                    <p>Hybridný fotovoltický systém je kombinácia solárnych panelov, batérií a elektrickej siete. 
                        Tento systém umožňuje využívať slnečnú energiu na napájanie domácností a zároveň ukladať prebytočnú energiu do batérií na neskoršie použitie.

                        V prípade, že slnečná energia nie je dostatočná alebo je potrebné dodatočné zaťaženie, systém môže čerpať energiu z elektrickej siete.
                    </p>
                    <h5>Výhody</h5>
                    <ul>
                        <li>nižšie náklady na elektrinu z elektrickej siete</li>
                        <li>elektrina je dostupná aj počas výpadkov</li>
                        <li>možnosť uchovania tiež vo virtuálnych batériách</li>
                    </ul>
                    <h5>Nevýhody</h5>
                    <ul>
                        <li>vyššia počiatočná investícia do zariadenia a inštalácie</li>
                        <li>potreba pravidelnej údržby a kontroly batérií a panelov</li>
                    </ul>

                    <!-- <p>Fotovoltický systém typu Hybrid je spojenie oboch hore uvedených systémov. <span class="text-white-50">U nás veľmi populárne.</span> 
                    </p>
                    <p>Sýstém dokáže pracovať aj pri pripojený v sieti aj samostatne. 
                        V bežných podmienkach, kde máte prístup k distribučnej sieti a tiež zakúpene alebo budete plánovať v budúcnosti zakúpiť fyzické batérie je tento systém ideálnym riešeným.
                        Pre postupné začatie s fotovoltickými systémami si možete zvoliť túto variantu, zakúpiť bez batérii a len si aktivovať virtuálnu batériu. 
                        O pár rokov keď budete vidieť využitie aj pre fyzickú batériu stačí nás osloviť a my Vám ju dovezieme a zamontujeme, nebude sa nič meniť a vyhadzovať.
                        Systém využíva fotovoltické panely na výrobu počas dňa a ukladá ich do fyzických batérii, pokiaľ budú fyzické batérie plné začne posielať prebytok energie do siete, 
                        ak budete mať zakúpene virtualne batérie bude sa prebytok ukladať do nich. Počas noci alebo slabej výroby je prvotne využívaná energia z fyzických batérii, za túto energiu neplatíte,
                        následne sa začne využívať energia z virtuálnych batérii.
                        Toto riešenie je najlepšia cesta k samostatnosti čo sa týka elektrickej energie, pri výpadkoch počas dňa Vás chránia fizycké báterie. 
                    </p>
                    <p>Toto riešenie je síce zo všetkých najdrahšie ale výdavky na energiu žnižuje najviac zo všetkých spomenutých, uvádza sa že až o 90%.
                    </p> -->
                </div>
                <div class="col text-center picture-hybrid ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-center">
                <div class="col text-center picture-balcon ma-image">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Balkónový systém</h3>
                    <p>Balkónový solárny systém je ideálnym riešením pre ľudí, ktorí chcú využívať obnoviteľnú energiu zo slnka, ale nemajú možnosť inštalovať klasické solárne panely na strechu. 
                        Skladá sa z jedného alebo viacerých solárnych panelov, ktoré sa umiestnia na balkóne, terase a z invertora.
                    </p>
                    <h5>Výhody</h5>
                    <ul>
                        <li>jednoduchá inštalácia bez stavebných prác</li>
                        <li>možnosť využiť nevyužitý priestor na balkóne alebo terase</li>
                        <li>zníženie poplatkov za elektrinu</li>
                        <li>prenášateľný pri sťahovaní</li>
                    </ul>
                    <h5>Nevýhody</h5>
                    <ul>
                        <li>nižší výkon, závisí od orientácie a oslnenia balkóna</li>
                        <li>invertor musí byť pripojený k zásuvke</li>
                        <li>estetický dopad na vzhľad budovy</li>
                    </ul>

                    <!-- <p>Fotovoltický systém typu Hybrid je spojenie oboch hore uvedených systémov. <span class="text-white-50">U nás veľmi populárne.</span> 
                    </p>
                    <p>Sýstém dokáže pracovať aj pri pripojený v sieti aj samostatne. 
                        V bežných podmienkach, kde máte prístup k distribučnej sieti a tiež zakúpene alebo budete plánovať v budúcnosti zakúpiť fyzické batérie je tento systém ideálnym riešeným.
                        Pre postupné začatie s fotovoltickými systémami si možete zvoliť túto variantu, zakúpiť bez batérii a len si aktivovať virtuálnu batériu. 
                        O pár rokov keď budete vidieť využitie aj pre fyzickú batériu stačí nás osloviť a my Vám ju dovezieme a zamontujeme, nebude sa nič meniť a vyhadzovať.
                        Systém využíva fotovoltické panely na výrobu počas dňa a ukladá ich do fyzických batérii, pokiaľ budú fyzické batérie plné začne posielať prebytok energie do siete, 
                        ak budete mať zakúpene virtualne batérie bude sa prebytok ukladať do nich. Počas noci alebo slabej výroby je prvotne využívaná energia z fyzických batérii, za túto energiu neplatíte,
                        následne sa začne využívať energia z virtuálnych batérii.
                        Toto riešenie je najlepšia cesta k samostatnosti čo sa týka elektrickej energie, pri výpadkoch počas dňa Vás chránia fizycké báterie. 
                    </p>
                    <p>Toto riešenie je síce zo všetkých najdrahšie ale výdavky na energiu žnižuje najviac zo všetkých spomenutých, uvádza sa že až o 90%.
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</section>