<app-header></app-header>
<div class="ma-basic">
  <router-outlet></router-outlet>
</div>
<app-cookies></app-cookies>
<app-footer></app-footer>

<button title="hore" type="button" class="btn btn-light btn-floating ma-btn" id="btn-back-to-top">
  <i class="fas fa-arrow-up"></i>
</button>

<div class="ma-phone" *ngIf="showPhone">
  <div class="ma-div">
          <a aria-label="+421905226666" class="tel-number" href="tel://00421905226666">+421 905 226 666</a>
          <a aria-label="+421902596666" class="tel-number1" href="tel://00421902596666">+421 902 596 666</a>
  </div>
</div>