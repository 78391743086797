import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class StoreRepositoryService {

  baseApiUrl: string = environment.baseApiUrl;
  constructor(private http: HttpClient) { }


/*   getAllPicture(): Observable<Gallery[]> {
    return this.http.get<Gallery[]>(this.baseApiUrl + '/api/gallery');
  }

  getPictureByType(type: number): Observable<Gallery[]> {
    return this.http.get<Gallery[]>(this.baseApiUrl + '/api/gallery/' + type);
  } */
}
