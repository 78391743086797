import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-solar-using-short',
  templateUrl: './solar-using-short.component.html',
  styleUrls: ['./solar-using-short.component.less']
})
export class SolarUsingShortComponent {
  constructor(@Inject(PLATFORM_ID) platformId: Object){
    if (isPlatformBrowser(platformId)) {
      window.addEventListener("scroll", (event)=>{
        const box = document.querySelector('.ma-us-container');
        if (box != null && (box.getBoundingClientRect().height - box.getBoundingClientRect().y >= 0)  ) {
          box.classList.add('container-anim');
        }
      });
    }
  }
}
