<section class="m-page-not-found-area">
    <div class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
            <h1 class="display-1 fw-bold">404</h1>
            <p class="fs-3"> <span class="text-danger">Opps!</span> Stránka nebola nájdená <span class="text-danger">:(</span></p>
            <p class="lead">
                Zrejme stránka, ktorú hladáš neexistuje!
            </p>
            <a href="" class="btn ma-thmBtn">Ideme na úvodnú?</a>
        </div>
    </div>
</section>