<section class="ma-clean-gallery">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div id="{{idCarousel}}" class="carousel slide" data-bs-ride="carousel" data-bs-touch="true" *ngIf="pictures.length != 0">
                    <ol class="carousel-indicators" >
                        <li id="galleryMenu" *ngFor="let item of pictures; let i = index" attr.data-bs-target="#{{idCarousel}}" attr.data-bs-slide-to="{{i}}" #forMenuInit><span class="d-flex justify-content-end ma-text-control">{{item.title}}</span></li>
                    </ol>
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let item of pictures" #forGalleryInit>
                            <div class="row align-items-center h-100">
                                <div class="col-md-5 text-center">
                                    <h3>{{ item.title }}</h3>
                                    <p>{{ item.description }}</p>
                                </div>
                                <div class="col"><img title="{{ item.title }}" src="{{ item.src }}"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center" *ngIf="pictures.length == 0">
                    <img sizes="50vw" height="300" width="200" *ngIf="type == 1" src="./../../../assets/images/zaffo.jpg" alt="Zaffo logo, tento obrázok je náhradou pôvodných obrázkov, ktoré sa nezobrazili kvôli technickej chybe">
                    <img sizes="50vw" height="300" width="200" *ngIf="type == 0" src="./../../../assets/images/filtro_olio.webp" alt="Obrázok olejového filtra Clean, tento obrázok je náhradou pôvodných obrázkov, ktoré sa nezobrazili kvôli technickej chybe">
                </div>
            </div>
        </div>
    </div>
</section>