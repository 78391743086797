<section class="ma-solar-diff-area ma-section-tile">
    <div class="container ">
        <div class="row text-center border-bottom pt-3">
            <h2>Rozdiel medzi solárnymi kolektormy a fotovoltickými panelmi</h2>
        </div>
        <div class="row mt-4 justify-content-around ma-attribute shadow">
            <div class="col-md-4 border-end h-100">
                <p>Fotovoltika a solárny systém sú dva spôsoby, ako využiť slnečnú energiu, ale fungujú na rôznych princípoch. 
                    Hlavný rozdiel medzi fotovoltikou a solárnym systémom je teda typ energie, ktorý sa získava zo slnka.
                </p>
            </div>
            <div class="col-md-4 border-end">
                <p> 
                    Fotovoltika premieňa slnečné žiarenie na elektrickú energiu pomocou fotovoltických panelov, ktoré obsahujú polovodičové prvky.
                    Produkuje elektrinu, ktorá sa môže použiť na napájanie rôznych spotrebičov v domácnosti alebo sa môže uskladniť v batériách. 
                </p>
            </div>
            <div class="col-md-4">
                <p>Solárny systém využíva solárne kolektory, ktoré ohrievajú vodu alebo inú teplonosnú látku pomocou slnečného tepla.
                    Produkuje teplo, ktoré sa môže použiť na ohrev vody pre osobnú hygienu, vykurovanie domu alebo bazéna.
                </p>
            </div>
        </div>
        <div class="ma-solar-diff-container mt-3">
            <div class="row mt-4 justify-content-start">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Fotovoltické panely</h3>
                    <p>
                        Fotovoltika je efektívnejšia pri premeně slnečnej energie na elektrinu, ako pri premene na teplo. 
                        Fotovoltické panely dokážu vyrábať elektrinu aj za zamračeného počasia alebo pri nízkej intenzite slnečného žiarenia. 
                        Tiež umožňuje znížiť závislosť od verejnej elektrickej siete a znížiť emisie skleníkových plynov. 
                    </p>
                </div>
                <div class="col text-center picture-fve ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-end">
                <div class="col text-center ma-image picture-thermo mb-2">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Solárne kolektory</h3>
                    <p>
                        Solárny systém je lacnejší a jednoduchší na inštaláciu a prevádzku. 
                        Solárne kolektory sú vhodné pre celé Slovensko, pretože rozdiel medzi najchladnejšími a najteplejšími regiónmi Slovenska v dopadajúcom množstve slnečnej energie je približne len 10 - 15 %. 
                        Tým pádom môže pokryť až 70 % ročnej spotreby teplej vody a znižiť náklady na jej ohrev o polovicu.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>