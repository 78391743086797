import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SolarPanelRoutingModule } from './solar-panel-routing.module';
import { SolarComponent } from './solar.component';
import { LayoutModule } from "../layout/layout.module";
import { SolarMainComponent } from './solar-main/solar-main.component';
import { SolarNavbarComponent } from './solar-navbar/solar-navbar.component';
import { SolarBenefitsComponent } from './solar-benefits/solar-benefits.component';
import { SolarHouseComponent } from './solar-house/solar-house.component';
import { SolarUsingComponent } from './solar-using/solar-using.component';
import { SolarKeyComponent } from './solar-key/solar-key.component';
import { SolarCompanyComponent } from './solar-company/solar-company.component';
import { SolarIntroComponent } from './solar-intro/solar-intro.component';
import { SolarBatteryComponent } from './solar-battery/solar-battery.component';
import { SolarUsingShortComponent } from './solar-using-short/solar-using-short.component';
import { SolarDifferencesComponent } from './solar-differences/solar-differences.component';
import { SolarWhyBuyComponent } from './solar-why-buy/solar-why-buy.component';
import { SolarRoofComponent } from './solar-roof/solar-roof.component';
import { SolarCarPowerComponent } from './solar-car-power/solar-car-power.component';
import { SolarCameraComponent } from './solar-camera/solar-camera.component';


@NgModule({
    declarations: [
        SolarComponent,
        SolarMainComponent,
        SolarNavbarComponent,
        SolarBenefitsComponent,
        SolarHouseComponent,
        SolarUsingComponent,
        SolarKeyComponent,
        SolarCompanyComponent,
        SolarIntroComponent,
        SolarBatteryComponent,
        SolarUsingShortComponent,
        SolarDifferencesComponent,
        SolarWhyBuyComponent,
        SolarRoofComponent,
        SolarCarPowerComponent,
        SolarCameraComponent
    ],
    imports: [
        CommonModule,
        SolarPanelRoutingModule,
        LayoutModule
    ]
})
export class SolarPanelModule { }
