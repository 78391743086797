<header class="ma-solar-header-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid">
            <!-- Brand and toggle get grouped for better mobile display -->
            <a aria-label="Fotovoltika" class="navbar-brand logo-h" [routerLink]="['./']"><img class="m-logo" src="../../../assets/images/solar/ground_panel.webp" alt="fotovoltika"></a>
            <a class="ma-sm navbar-brand text-white" [routerLinkActive]="['active']" [routerLink]="['./']" aria-label="Hlavna stránka">
                <span>Fotovoltika</span>
            </a>
            <button id="solarNavbarBtn" class="navbar-toggler" title="Zavrieť menu" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                <ul class="nav navbar-nav menu_nav ml-auto solar">
                    <li class="nav-item">
                        <a aria-label="Fotovoltika" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['./']" data-bs-target="#navbarSupportedContent">Fotovoltické systémy</a>
                    </li> 
                    <li class="nav-item">
                        <a aria-label="Fotovoltika na klúč" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['key']"  data-bs-target="#navbarSupportedContent">Fotovoltika na klúč</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" aria-label="Produkty" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" aria-controls="navbarDropdownMenuLink">
                            Produkty<span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu fade-up" aria-labelledby="navbarDropdownMenuLink">
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Predpripravené balíky systémov" [routerLinkActive]="['active']" [routerLink]="['house']" data-bs-target="#navbarNavDropdown">Predpripravené balíky systémov</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Nabíjacie stanice" [routerLinkActive]="['active']" [routerLink]="['carEnergy']" data-bs-target="#navbarNavDropdown">Nabíjacie stanice</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Solárna strecha" [routerLinkActive]="['active']" [routerLink]="['solarRoof']" data-bs-target="#navbarNavDropdown">Solárna strecha</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Solárne slnečníky" [routerLinkActive]="['active']" [routerLink]="['solarUmbrella']" data-bs-target="#navbarNavDropdown">Solárne slnečníky</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Solárne ruksaky" [routerLinkActive]="['active']" [routerLink]="['solarBackpack']" data-bs-target="#navbarNavDropdown">Solárne ruksaky</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Solárne kamery" [routerLinkActive]="['active']" [routerLink]="['solarCamera']" data-bs-target="#navbarNavDropdown">Solárne kamery</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Tepelné čerpadlá" [routerLinkActive]="['active']" [routerLink]="['heaterPump']" data-bs-target="#navbarNavDropdown">Tepelné čerpadlá</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" aria-label="Produkty" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" aria-controls="navbarDropdownMenuLink">
                            Informácie<span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu fade-up" aria-labelledby="navbarDropdownMenuLink">
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Typy fotovoltík" [routerLinkActive]="['active']" [routerLink]="['typefve']" data-bs-target="#navbarNavDropdown">Typy fotovoltík</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Batérie" [routerLinkActive]="['active']" [routerLink]="['battery']" data-bs-target="#navbarNavDropdown">Batérie</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Nie je solárny systém ako solárny systém" [routerLinkActive]="['active']" [routerLink]="['diff']" data-bs-target="#navbarNavDropdown">Nie je solárny systém <br>&nbsp;&nbsp;ako solárny systém</a>
                            </li>
                            <li class="dropdown-item-li">
                                <a class="dropdown-item" aria-label="Nie je solárny systém ako solárny systém" [routerLinkActive]="['active']" [routerLink]="['whyBuy']" data-bs-target="#navbarNavDropdown">Prečo sa to oplatí?</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a aria-label="Fotovoltika pre firmy" class="nav-link" [routerLinkActive]="['active']" [routerLink]="['company']"  data-bs-target="#navbarSupportedContent">Fotovoltika pre firmy</a>
                    </li>
                </ul>
            </div> 
            </div>
        </nav>
    </div>
</header>