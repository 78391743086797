<section class="ma-zaffo-area ma-section-tile">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <p>Najvyššia rada aktívnych uhlíkových kabínových filtrov</p>
                <h4>Technologická charakteristika</h4>
                <p>Moderné spojenie materiálu vedené laboratórnymi skúškami dalo život filtru ZAFFO, prvým kabínovým filtrom s aktívnym uhlíkom pre automobily. 
                    Tento produkt je svetovo patentovaný, takže jeho originálne a účinne vlastnosti ako aj jeho technologické a funkčné výsledky sú pri čistení zrejmé. 
                    ZAFFO filter pozostáva zo spolupôsobiacej acrylovej živice a aktívneho uhlíka, ktorý zachytáva všetky exhaláty, ktoré by mohli z vonkajšieho priestoru cez vzduchové klapky vniknúť do vnútorného priestoru vozidla.</p>
                <p> Okrem prachu a peľu dokáže zadržať všetky znečisťujúce látky produkované dopravnými prostriedkami, kadmium, vanád, benzén a olovo.</p>
            </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button id="tableUcinostBtn" class="btn ma-btn" data-bs-toggle="collapse" href="#zaffoInfo" type="button" 
                    aria-expanded="false" title="Tabuľka účinnosti" aria-controls="inPrice">Tabuľka účinnosti</button>
          </div>
          <div class="row mt-2 justify-content-center">
            <div class="col-auto">
                <div class="collapse multi-collapse" id="zaffoInfo">
                <div class="mt-2">
                    <app-zaffo-explain></app-zaffo-explain>
                </div>
                </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <app-gallery [type]=1 idCarousel="zaffoCarousel"></app-gallery>
          </div>
        </div>
    </div>
</section>