import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-battery',
  templateUrl: './solar-battery.component.html',
  styleUrls: ['./solar-battery.component.less']
})
export class SolarBatteryComponent {

}
