<section class="ma-solar-intro-area ma-section-tile bg-image">
    <div class="container ma-intro-container mt-3 h-100">
        <div class="row text-center">
            <div class="col text-end">
                <h2 class="text-uppercase">Prinášame Vám energiu priamo zo zdroja</h2>
                <p>v podobe fotovoltického systému na mieru, za <span class="fst-italic">bezkonkurenčné ceny</span></p>
                <p>plánujeme, riešime a premieňame Vaše ekologické sny v realitu</p>
            </div>
        </div>
        <div class="row text-center h-80 align-items-end m-auto">
            <div class="row">
                <div class="col ma-hover">
                    <img width="90" height="80" src="../../../assets/images/solar/sun_solar.webp" alt="..." class="ma-img-intro">
                    <p class="m-auto pt-1 w-75">výkon slnka na zemi je cez 17x10<sup>16</sup>W, prečo to nevyužiť?</p>
                </div>
                <div class="col ma-hover">
                    <img width="90" height="85" src="../../../assets/images/solar/charging_station.webp" alt="..." class="ma-img-intro">
                    <p class="m-auto pt-1 w-75">o krok bližšie k nezávislosti</p>
                </div>
                <div class="col ma-hover">
                    <img width="100" height="85" src="../../../assets/images/solar/recyclation.webp" alt="..." class="ma-img-intro">
                    <p class="m-auto pt-1 w-75">využiteľnosť obnoviteľných zdrojov</p>
                </div>
                <div class="col ma-hover">
                    <img width="90" height="85" src="../../../assets/images/solar/hand_ecology.webp" alt="..." class="ma-img-intro">
                    <p class="m-auto pt-1 w-75">myslíme na budúcnosť</p>
                </div>
            </div>
        </div>
    </div>
</section>
<app-solar-main></app-solar-main>
<app-solar-benefits></app-solar-benefits>
<app-solar-using-short></app-solar-using-short>