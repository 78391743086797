<section class="ma-why-buy-area ma-section-tile">
    <div class="container ">
        <div class="row text-center border-bottom pt-3">
            <h2>Prečo investovať do fotovoltiky?</h2>
        </div>
        <div class="row mt-4">
            <div class="col-auto ma-font-size-higher">
                <p>Fotovoltika je technológia, ktorá využíva slnečné žiarenie na výrobu elektrickej energie.
                    Fotovoltika funguje na princípe fotoelektrického javu, pri ktorom dopadom fotónov na polovodičový materiál dochádza k uvoľňovaniu a hromadeniu voľných elektrónov, ktoré vytvárajú elektrické napätie a prúd.
                </p>
            </div>
        </div>
        <div class="ma-why-buy-container">
            <div class="row mt-4 justify-content-start">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Naozajstné úspory</h3>
                    <p>Fotovoltika sa tvári ako drahá investícia, tak prečo míňať nasporené peňazia?</p>
                    <p>
                        Pri aktuálnych cenách energie, je to jeden zo spôsobov ako znížiť náklady na elektrickú energiu a vybudovať si finančné úspory. 
                        Počiatočná investícia je síce väčšia ale potom už šetríte a odložene peniaze môžete využiť inak. Fotovoltika sa stáva čoraz dostupnejšou a efektívnejšou technológiou. 
                        Ceny fotovoltických panelov a zariadení klesajú, zatiaľ čo ich výkon a životnosť rastú. 
                        Podľa ZSE, fotovoltika sa oplatí aj bez štátneho príspevku, pretože dokáže ušetriť stovky eur ročne na nákladoch na elektrinu.
                    </p>
                    <p>
                        Je podporovaná štátom a Európskou úniou. Môžete sa uchádzať o rôzne dotácie, príspevky alebo úvery na financovanie vašej fotovoltickej elektrárne. 
                        Vďaka tomuto sa správne nastavená fotovoltická inštalácia môže zaplatiť aj za menej ako 10 rokov. 
                        Životnosť fotovoltických panelov je zvyčajne 25 až 30 rokov, čo znamená, že po splatení investície budete mať zadarmo elektrinu po dlhú dobu.
                    </p>
                </div>
                <div class="col text-center picture-solar-saving ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-end">
                <div class="col text-center ma-image picture-slovak mb-2">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Využime potenciál Slovenska</h3>
                    <p>
                        Fotovoltika umožňuje využiť potenciál slnečnej energie na Slovensku. 
                        Slovensko má relatívne vysokú intenzitu slnečného žiarenia, ktorá sa pohybuje od 900 do 1300 kWh/m2 ročne. 
                        To znamená, že fotovoltické panely môžu produkovať dostatok elektriny aj v oblačných alebo zimných dňoch.
                    </p>
                </div>
            </div>
            <div class="row mt-4 justify-content-center">
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Všestrannosť</h3>
                    <p>
                        Fotovoltika zvyšuje energetickú bezpečnosť a nezávislosť. 
                        V prípade výpadku elektrickej siete alebo krízovej situácie môžete mať stále prístup k elektrine z vlastnej fotovoltickej elektrárne. 
                        Ak máte batériu na ukladanie prebytočnej energie, môžete ju použiť aj v noci alebo v období nižšej produkcie.
                    </p>
                </div>
                <div class="col text-center picture-versatility ma-image">
                </div>
            </div>
            <div class="row mt-4 justify-content-center">
                <div class="col text-center picture-solar-roof ma-image">
                </div>
                <div class="col-md-6 ma-font-size-higher">
                    <h3 class="text-uppercase">Rôzne spôsoby montáže</h3>
                    <p>
                        Ponúka tiež rôzne možnosti využitia a integrácie do existujúcich systémov. 
                        Fotovoltické panely môžu byť inštalované v strechách alebo na strechách, fasádach, balkónoch, záhradách alebo voľných plochách. 
                        Môžu byť tiež kombinované s inými technológiami, ako napríklad tepelnými čerpadlami, akumulačnými nádržami alebo batériami. 
                        Tieto riešenia umožňujú optimalizovať spotrebu a distribúciu elektrickej energie podľa potrieb domácnosti.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>