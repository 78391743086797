import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
declare function headerActiveSolar():any;
declare var headerSolar:any;

@Component({
  selector: 'app-solar-navbar',
  templateUrl: './solar-navbar.component.html',
  styleUrls: ['./solar-navbar.component.less']
})
export class SolarNavbarComponent {
  platformId: Object;
  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, @Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId; 
    const scriptElt = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElt, 'type', 'text/javascript');
    this.renderer.setAttribute(scriptElt, 'src', './assets/scripts/header.js');
    this.renderer.appendChild(this.document.head, scriptElt);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      headerActiveSolar();
      headerSolar.fixedHeader();
    }
  }
}

