<section class="ma-solar-main-area ma-section-tile">
    <div class="container">
        <div class="row text-center border-bottom justify-content-around">
            <div class="col-md-4 align-self-center">
                <h1 class="text-uppercase">Vyriešime vysoké účty energie za Vás</h1>
                <span class="fs-4 text-muted">Prinášame Vám fotovoltické panely rôznych výkonov, za prijateľné ceny k tomu s akciou až -20%</span>
            </div>
            <div class="col-md-6">
                <img src="../../../assets/images/solar/obrazok.webp" width="700" height="500" sizes="50vw" alt="Dom ktorý je napájaní fotovoltickým systémom" class="img-thumbnail ma-img-intro">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-solid fa-walkie-talkie me-3"></i>Fotovoltické invertory</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Invertory sú kľúčové, premienajú slnečnú energiu na elektrickú. Naše invertory sa postarajú o všetko, čo potrebujete pre bezproblémový chod vášho fotovoltického systému.</p>
                        <!-- <p class="ma-attribute-text">Dôverujete inému výrobcovi invertoru? Pre nás to nie je žiadny problém. Zabezpečíme Vám výrobcu, máme na výber troch konkurencie schopných</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><span class="ma-attribute-icon fa-solid fa-solar-panel me-3"></span>Panely rôznych výkonov</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Ak máte menšiu strechu sme schopný Vám zabezpečiť jeden fotovoltický panel s výkonom až 560W, pokiaľ nemáte núdzu o priestor máte na výber aj zo 440W/380W panelov</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><span class="ma-attribute-icon ma-icon ma-grid-icon me-3"></span>On-Grid/Off-Grid/Hybrid</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Tiež sa strácate v anglických skratkách fotovoltických systémov? Nemajte strach nás osloviť a vyberieme Vám systém podľa predstáv, poradíme a vypracujeme cenovú ponuku na mieru</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><span class="ma-attribute-icon ma-icon ma-battery-icon me-3"></span>Záložná batéria alebo virtuálna batéria</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Neviete sa rozhodnúť čo je pre Vás lepšie? Zabezpečíme Vám jedno alebo druhé, prípadne aj oboje. Povieme Vám pre a proti a rozhodnutie bude na Vás</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-sharp fa-solid fa-tags me-3"></i>Zľavy za odporúčanie</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Boli ste so službami spokojný? Odporučte nás! Ak si Váš známy kúpi od nás systém získate cashback až do 10%</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-3">
                <div class="ma-attribute shadow">
                    <div class="item-head">
                        <h4 class="ma-attribute-label"><i class="ma-attribute-icon fa-solid fa-key me-3"></i>Na kľuč alebo aj bez kľúča</h4>
                    </div>
                    <div class="item-content mt-2">
                        <p class="ma-attribute-text">Veríte si na toľko, že si fotovoltické panely zamontujete aj na vlastnú réžiu? Iné firmy Vám to nechcú dovoliť? U nás to nie je problém. Predáme Vám celý systém za nižšiu sumu</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col text-center">
                <h3>Ak ste sa rozhodli? Kontaktujte nás, ak ešte nie pokračujeme ďalej</h3>
                <div class="ma-contact-btn">
                    <button id="solarMainContactBtn" title="Kontaktovať" [routerLink]="['/contact']" class="btn m-4" (click)="setGlobalVar()">Kontaktovať</button>
                    <button title="Predpripravené konfigurácie" [routerLink]="['/solar/house']" class="btn m-4">Predpripravené konfigurácie</button>
                </div>
            </div>
        </div>
    </div>
</section>