<app-breadcrumb></app-breadcrumb>
<section class="ma-about-area ma-section-tile">
    <div class="container">
        <div class="row">
            <div class="col-md-6 align-items-center">
                <div class="ma-about-content ">
                    <h2 class="title">O nás <br>Naša história<br>Misia & Vízia</h2>
                    <p>
                        Firma Sjada s.r.o. sa už desiatky rokov orientuje na dovoz, distribúciu a predaj na Slovensku a v zahraničí.
                        Máme skladové zásoby značiek Talianskych výrobcov, z ktorých vypredávame výfukové systémy Marmitezara, filtre Clean, peľové filtre Zaffo, brzdové a hydraulické komponenty Samko, motorové oleje Fiat Lubrificanti a ďalšie. 
                    </p>
                    <p>
                        Okrem toho sme rozšírili naše portfólio o fotovoltické systémy, ktoré predstavujú budúcnosť obnoviteľných zdrojov energie. 
                        V budúcnosti plánujeme rozšíriť naše portfólio o ďalšie produkty, ktoré súvisia s obnoviteľnou energiou a ekológiou.
                        Veríme, že tým prispejeme k zníženiu uhlíkovej stopy a ochrane životného prostredia.
                    </p>
                    <p>                        
                        Navyše ponúkame aj službu na mieru a poradenstvo, ako ušetriť peniaze a energiu vďaka fotovoltike.
                        Naše služby zahŕňajú návrh, inštaláciu a predaj fotovoltických systémov pre domácnosti aj firmy. 
                        Tiež podporujeme veľkoobchodný predaj pre našich partnerov a dodávateľov.
                    </p>

                    <!-- <p>Firma Sjada s.r.o. sa už niekoľko rokov orientuje na dovoz a distribúciu auto dielov na celom území Slovenska do predajní autosúčiastok a autoservisov. Určením vyhovujú celému vozovému parku osobných a nákladných áut jazdiacich na Slovensku.</p>
                    <p>Na sklade máme výfukové systémy od výrobcu Marmittezara, filtre Clean, peľové filtre Zaffo, brzdové a hydraulické komponenty Samko, 
                        motorové oleje Fiat Lubrificanti, všetko sú kvalitné výrobky z Talianska.</p>
                    <p>Po novom sme sa začali špecializovať na fotovoltické systémy a v budúcnosti máme plány naše portfólio rožšíriť o ďalšie služby obnovitelných energii. 
                        Veríme v lepšiu budúcnosť preto sa snažíme snižovať uhlíkovú stopu po celom Slovensku. Navrhneme Vám službu na mieru a porádime ako sa ešte dajú ušetriť peniaze a energia.
                        Špecializujeme sa na navrhovanie, inštaláciu a predaj fotovoltických systémov, ktoré spĺňajú všetky európske normy a požiadavky klientov. 
                        Taktiež podporujeme veľkoobchodný predaj fotovoltických systémov.</p> -->
                    <p>Filozofiou našej firmy je poskytnúť zákazníkom kvalitné výrobky za prijateľnú cenu.</p>
                </div>
            </div>
            <div class="col-md-6 mt-2">
                <img width="100%" height="100%" class="img-fluid shadow" src="../../assets/images/auto.webp" alt="Budúcnosť technológií. Elektrický automobil, ktorý má úsporné, jednoduché a futuristické led svetlá. Je napájaný fotovoltickými panelmi. V pozadí je dom, ktorý má na streche fotovoltické panely, ktoré mu napájajú celý dom.">
            </div>
        </div>
    </div>
</section>