<section class="ma-cookies-eu-area">
    <div class="container">
        <div class="row mt-4 justify-content-center">
            <h1>Zásady cookies (EU)</h1>
            <p>
                Tieto zásady používania súborov cookie boli naposledy aktualizované máj 01, 2023 a vzťahujú sa na občanov a osoby s trvalým pobytom v Európskom hospodárskom priestore.
            </p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>1. Úvod</h4>
            <p>Naša webová stránka, https://www.sjada.sk (ďalej len „webová stránka“) používa súbory cookie a ďalšie súvisiace technológie (všetky technológie sa kvôli pohodliu označujú ako „súbory cookie“). Súbory cookie umiestňujú aj tretie strany, ktoré sme zapojili. V nižšie uvedenom dokumente Vás informujeme o používaní súborov cookie na našich webových stránkach.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>2. Čo sú cookies?</h4>
            <p>Cookie je malý jednoduchý súbor, ktorý je odoslaný spolu so stránkami tohto webu a uložený vašim prehliadačom na pevnom disku Vášho počítača alebo iného zariadenia. Informácie v nich uložené môžu byť vrátené na naše servery alebo na servery príslušných tretích strán počas nasledujúcej návštevy.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>3. Čo sú skripty?</h4>
            <p>Skript je časť programového kódu, ktorý sa používa na správnu a interaktívnu funkciu našich webových stránok. Tento kód sa spustí na našom serveri alebo na Vašom zariadení.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>4. Čo je to webový maják?</h4>
            <p>Webový maják (alebo pixelová značka) je malý neviditeľný text alebo obrázok na webovej stránke, ktorý sa používa na monitorovanie návštevnosti webových stránok. Za týmto účelom sa pomocou webových majákov ukladajú rôzne údaje o Vás.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>5. Cookies</h4>
        </div>
        <div class="row mt-4 justify-content-center">
            <h5>5.1 Technické alebo funkčné cookies</h5>
            <p>Niektoré súbory cookie zaisťujú, že určité časti webových stránok fungujú správne a že vaše preferencie používateľov zostanú známe. Umiestnením funkčných súborov cookie Vám uľahčujeme návštevu našich webových stránok. Takto nemusíte pri návšteve našich webových stránok opakovane zadávať rovnaké informácie a napríklad položky zostanú vo Vašom nákupnom košíku, kým nezaplatíte. Tieto súbory cookie môžeme umiestňovať bez Vášho súhlasu.</p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>6. Umiestnené cookies</h4>
            <div class="row ps-4 pe-4">
                <div class="row border rounded">
                    <button title="Google Analytics" class="btn text-start" data-bs-toggle="collapse" href="#googleAnalytics" type="button" 
                        aria-expanded="false" aria-controls="googleAnalytics">Google Analytics</button>
                </div>
                <div class="row mt-2">
                    <div class="collapse multi-collapse" id="googleAnalytics">
                        <div class="card card-body">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="3">Štatistické</th>
                                    </tr>
                                    <tr>
                                        <th>Meno</th>
                                        <th>Expirácia</th>
                                        <th>Funkcia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><a aria-label="_ga" class="text-black" target="_blank" href="https://cookiedatabase.org/cookie/google-analytics/_ga/">_ga</a></td>
                                    <td>2 roky</td>
                                    <td>Uchovávať a počítať zobrazenie stránok</td>
                                </tr>
                                <tr>
                                    <td><a aria-label="_gid" class="text-black" target="_blank" href="https://cookiedatabase.org/cookie/google-analytics/_gid/">_gid</a></td>
                                    <td>2 roky</td>
                                    <td>Uchovávať a počítať zobrazenie stránok</td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="3">Funkčné, nutné</th>
                                    </tr>
                                    <tr>
                                        <th>Meno</th>
                                        <th>Expirácia</th>
                                        <th>Funkcia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td><a aria-label="_gat UA-*" class="text-black" target="_blank" href="https://cookiedatabase.org/cookie/google-analytics/_gat_ua-2/">_gat UA-*</a></td>
                                    <td>relácia</td>
                                    <td>Poskytnúť technický monitoring</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ps-4 pe-4">
                <div class="row border rounded">
                    <button title="Google maps" class="btn text-start" type="button" data-bs-toggle="collapse" href="#googleMaps" 
                        aria-expanded="false" aria-controls="googleMaps">Google maps</button>
                </div>
                <div class="row mt-2">
                    <div class="collapse multi-collapse" id="googleMaps">
                        <div class="card card-body">
                            <table class="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th colspan="3">Funkčné, nutné</th>
                                    </tr>
                                    <tr>
                                        <th>Meno</th>
                                        <th>Expirácia</th>
                                        <th>Funkcia</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><a aria-label="Google Maps API" class="text-black" target="_blank" href="https://cookiedatabase.org/cookie/google-maps/google-maps-api/">Google Maps API</a></td>
                                        <td>vyprší okamžite</td>
                                        <td>Získať IP adresu, pre navigáciu</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>7. Súhlas</h4>
            <p>Keď navštívite náš web prvýkrát, ukážeme Vám kontextové okno s vysvetlením o súboroch cookie. Akonáhle kliknete na „Uložiť predvoľby“, súhlasíte s tým, že použijeme kategórie súborov cookie a doplnkov, ktoré ste vybrali v kontextovom okne, ako je to popísané v týchto zásadách používania súborov cookie. Používanie súborov cookie môžete zakázať prostredníctvom svojho prehliadača, ale upozorňujeme, že náš web už nemusí správne fungovať.</p>
            <div class="row ">
                <h5>7.1 Spravujte svoje nastavenia súhlasu</h5>
                <div class="row ps-4 pe-4">
                    <div class="row border rounded">
                        <button title="Funkčné" class="btn text-start" data-bs-toggle="collapse" href="#functionCookie" type="button" 
                            aria-expanded="false" aria-controls="functionCookie">Funkčné</button>
                    </div>
                    <div class="row mt-2">
                        <div class="collapse multi-collapse" id="functionCookie">
                            <div class="card card-body text-black">
                            <p>Technické uloženie alebo prístup sú nevyhnutne potrebné na legitímny účel umožnenia použitia konkrétnej služby, ktorú si účastník alebo používateľ výslovne vyžiadal, alebo na jediný účel vykonania prenosu komunikácie cez elektronickú komunikačnú sieť.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>8. Povolenie/zakázanie a odstraňovanie súborov cookie</h4>
            <p>Váš internetový prehliadač môžete použiť na automatické alebo manuálne mazanie cookies. Môžete tiež špecifikovať, že niektoré súbory cookie nemusia byť umiestnené. Ďalšou možnosťou je zmeniť nastavenia svojho internetového prehliadača tak, aby ste dostali správu vždy, keď sa umiestni súbor cookie. Ďalšie informácie o týchto možnostiach nájdete v pokynoch v časti Pomocník Vášho prehliadača.
                <br>Upozorňujeme, že ak sú všetky súbory cookie zakázané, náš web nemusí fungovať správne. Pokiaľ cookies vymažete vo svojom prehliadači, budú znova umiestnené po Vašom súhlase, keď znova navštívite naše webové stránky.
            </p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>9. Vaše práva v súvislosti s osobnými údajmi</h4>
            <p>
                Pokiaľ ide o Vaše osobné údaje, máte nasledujúce práva:
            </p>
            <ul class="ms-5">
                <li>Máte právo vedieť, prečo sú vaše osobné údaje potrebné, čo sa s nimi stane a ako dlho budú uchovávané.</li>
                <li>Právo na prístup: máte právo na prístup k svojim osobným údajom, ktoré sú nám známe.</li>
                <li>Právo na opravu: máte právo kedykoľvek doplniť, opraviť, vymazať alebo zablokovať Vaše osobné údaje.</li>
                <li>Ak nám udelíte súhlas so spracovaním Vašich údajov, máte právo tento súhlas odvolať a vymazať Vaše osobné údaje.</li>
                <li>Právo na prenos Vašich údajov: máte právo požadovať od správcu všetky svoje osobné údaje a preniesť ich v celom rozsahu k ďalšiemu prevádzkovateľovi.</li>
                <li>Právo namietať: môžete vzniesť námietku proti spracovaniu Vašich údajov. Dodržiavame to, pokiaľ neexistujú odôvodnené dôvody na spracovanie.</li>
            </ul>
            <p>
                Ak chcete uplatniť tieto práva, kontaktujte nás. Pozrite sa na kontaktné údaje v spodnej časti týchto zásad používania súborov cookie. Ak máte sťažnosť na to, ako narábame s Vašimi údajmi, radi by sme Vás vypočuli, ale máte tiež právo podať sťažnosť dozornému orgánu (úradu na ochranu údajov).
            </p>
        </div>
        <div class="row mt-4 justify-content-center">
            <h4>10. Kontaktné údaje</h4>
            <p>
                Ak máte otázky alebo pripomienky k našim zásadám používania súborov cookie a tomuto vyhláseniu, kontaktujte nás pomocou nasledujúcich kontaktných údajov:
            </p>
            <p class="ms-3">
                Sjada s.r.o.
                <br>Závodie 18/20
                <br>976 52 Čierny Balog
                <br>Slovensko
                <br>Webová stránka: <a href="https://www.sjada.sk">https://www.sjada.sk</a>
                <br>E-mail: <a href="mailto:sjada@sjada.sk">sjada@sjada.sk</a>
            </p>
            <p>Tieto zásady používania súborov cookie boli synchronizované s serverom <a href="cookiedatabase.org" target="_blank">cookiedatabase.org</a> v máji 01, 2023</p>
        </div>
    </div>
</section>