import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebComponent } from './web.component';
import { PageWorkingComponent } from '../layout/page-working/page-working.component';

const routes: Routes = [
  {  
    path: '',
    component: PageWorkingComponent,//WebComponent,
    data: { breadcrumb: 'Web servis' } 
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebServiceRoutingModule { }
