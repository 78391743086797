import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { LayoutModule } from './layout/layout.module';
import { EShopModule } from './e-shop/e-shop.module';
import { OroCafeModule } from './oro-cafe/oro-cafe.module';
import { SolarPanelModule } from './solar-panel/solar-panel.module';
import { WebServiceModule } from './web-service/web-service.module';
import { MainModule } from './main/main.module';
import { HttpClientModule } from '@angular/common/http';
import { RentComponent } from './rent/rent.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import * as $ from "jquery";
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    RentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    EShopModule,
    OroCafeModule,
    SolarPanelModule,
    WebServiceModule,
    MainModule,
    HttpClientModule
  ],
  exports: [

  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    Meta,
    CookieService
    //todo authentifikacia
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
