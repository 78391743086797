import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OroCafeRoutingModule } from './oro-cafe-routing.module';
import { OroComponent } from './oro.component';
import { LayoutModule } from "../layout/layout.module";


@NgModule({
    declarations: [
        OroComponent
    ],
    imports: [
        CommonModule,
        OroCafeRoutingModule,
        LayoutModule
    ]
})
export class OroCafeModule { }
