<section class="ma-zaffo-explain-area">
    <div class="container">
        <div class="row">
            <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th scope="col">Kontaminovaná látka<br> Prášok</th>
                    <th scope="col">Koncetrácia <br>Vrchná čast</th>
                    <th scope="col">Koncentrácia <br>Spodná časť</th>
                    <th scope="col">Výkon %</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>160</td>
                    <td>80</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>90</td>
                    <td>40</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>110</td>
                    <td>50</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <th scope="row">Olovo</th>
                    <td>0,37</td>
                    <td>0,18</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th scope="row">Chróm</th>
                    <td>0,018</td>
                    <td>0,010</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <th scope="row">Nikel</th>
                    <td>0,030</td>
                    <td>0,012</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <th scope="row">Kadmium</th>
                    <td>0,002</td>
                    <td>0,001</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <th scope="row">Vanádium</th>
                    <td>0,036</td>
                    <td>0,020</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <th scope="row">Benzol</th>
                    <td>-</td>
                    <td>-</td>
                    <td>81</td>
                  </tr>
                </tbody>
              </table>
        </div>
        <div class="row">
            <div class="col text-center">
                <p>Účinnosť týchto technologických riešení filtrov ZAFFO bola odskúšaná vedeckými testmi, ktoré boli preverené Medzinárodnou zdravotnou službou. 
                    Filter, ako hore vidieť v tabuľke č.1, zadržiava priemerne viac ako 50 % z každej uvedenej znečistenej látky ktorá sa uhniezdi v kabíne vozidla</p>
            </div>
        </div>
    </div>
</section>