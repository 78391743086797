import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { inject } from '@angular/core/testing';
import { Router } from '@angular/router';
import { throwError, BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { RegistrationDto, User } from 'src/app/models/contactModel';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  platformId: Object;
  
  constructor(private router: Router, private http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
      this.platformId = platformId;  
      //console.log('setCurentUser', isPlatformBrowser(this.platformId));
      this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
      this.user = this.userSubject.asObservable();
      
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(username: string, password: string) {
    console.log({ username, password });
    return this.http.post<User>(`${environment.apiUrl}/api/token/authenticate`, { username, password })
        .pipe(map(user => {
          console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }));
  }

  registration(registrationDto: RegistrationDto) {
    console.log(registrationDto);
    return this.http.post<RegistrationDto>(`${environment.apiUrl}/api/User/registration`, registrationDto)
        .pipe(map(user => {
          console.log(user);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    console.log("log off");
    this.userSubject.next(null);
    this.router.navigate(['/shop/login']);
  }
}