import { Component } from '@angular/core';

@Component({
  selector: 'app-zara',
  templateUrl: './zara.component.html',
  styleUrls: ['./zara.component.less']
})
export class ZaraComponent {

}
