import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-car-power',
  templateUrl: './solar-car-power.component.html',
  styleUrls: ['./solar-car-power.component.less']
})
export class SolarCarPowerComponent {

}
