import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebServiceRoutingModule } from './web-service-routing.module';
import { WebComponent } from './web.component';
import { LayoutModule } from "../layout/layout.module";


@NgModule({
    declarations: [
        WebComponent
    ],
    imports: [
        CommonModule,
        WebServiceRoutingModule,
        LayoutModule
    ]
})
export class WebServiceModule { }
