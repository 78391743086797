import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-key',
  templateUrl: './solar-key.component.html',
  styleUrls: ['./solar-key.component.less']
})
export class SolarKeyComponent {

}
