<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="row">
        <div class="col-md-12 pb-2">
            <div class="form-group">
                <input type="text" class="form-control" formControlName="name" id="name" placeholder="Meno*">
                <div *ngIf="f['name'].touched && f['name'].invalid" class="alert alert-danger">
                    <div *ngIf="f['name'].errors && f['name'].errors['required']">Meno je povinný parameter.</div>
                    <div *ngIf="f['name'].errors && f['name'].errors['minlength']">Meno musí obsahovať aspoň 5 znaky.</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pb-2"> 
            <div class="form-group">
                <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email*">
                <div *ngIf="f['email'].touched && f['email'].invalid" class="alert alert-danger">
                    <div *ngIf="f['email'].errors && f['email'].errors['required']">Email je povinný parameter.</div>
                    <div *ngIf="f['email'].errors && f['email'].errors['email']">Prosím zadajte správny email.</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pb-2" [hidden]="!isMobile"> 
            <div class="form-group">
                <input type="phone" class="form-control" formControlName="phone" id="phone" placeholder="Telefóne číslo*">
                <div *ngIf="f['phone'].touched && f['phone'].invalid" class="alert alert-danger">
                    <div *ngIf="f['phone'].errors && f['phone'].errors['required']">Mobil je povinný parameter.</div>
                    <div *ngIf="f['phone'].errors && f['phone'].errors['phone']">Prosím zadajte správne mobilné číslo.</div>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12 pb-2">
            <div class="form-group">
                <input type="text" class="form-control" formControlName="subject" id="subject" placeholder="Predmet*">
                <div *ngIf="f['subject'].touched && f['subject'].invalid" class="alert alert-danger">
                    <div *ngIf="f['subject'].errors && f['subject'].errors['required']">Predmet je povinný parameter.</div>
                    <div *ngIf="f['subject'].errors && f['subject'].errors['minlength']">Predmet musí mať aspoň 4 znaky.</div>
                </div>
            </div>
        </div> -->
        <div class="col-md-12 pb-2">
            <div class="form-group">
                <textarea formControlName="message" class="form-control" id="message" cols="30" rows="5" placeholder="*Ak máte akékoľvek otázky, pripomienky alebo chcete nezáväznú ponuku, neváhajte nás kontaktovať. Tešíme sa na Vašu spoluprácu a ďakujeme Vám, že využívate naše služby.                 
Sjada s.r.o."></textarea>
                <div *ngIf="f['message'].touched && f['message'].invalid" class="alert alert-danger">
                    <div *ngIf="f['message'].errors && f['message'].errors['required']">Správa je povinný parameter.</div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pb-2">
            <div class="form-group">
                <input type="checkbox" class="form-check-input" formControlName="cookies" id="cookies" value="Súhlasím so spracovaním osobných údajov" required>
                <label class="form-check-label ms-2" for="cookies">Súhlasím so spracovaním osobných údajov</label>
                <div *ngIf="f['cookies'].touched && f['cookies'].invalid" class="alert alert-danger">
                    <div *ngIf="f['cookies'].errors && f['cookies'].errors['required']">Cookies je povinný parameter.</div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <button id="submitContact" title="Poslať správu" type="submit" [disabled]="form.invalid" class="btn ma-btn">Poslať správu</button>
            </div>
        </div>
    </div>
</form>