import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { CookiesComponent } from './cookies/cookies.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ContactComponent } from './contact/contact.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageWorkingComponent } from './page-working/page-working.component';
import { GdprComponent } from './gdpr/gdpr.component';
import { CookiesEuComponent } from './cookies-eu/cookies-eu.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    CookiesComponent,
    BreadcrumbComponent,
    ContactComponent,
    ContactFormComponent,
    PageWorkingComponent,
    GdprComponent,
    CookiesEuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    CookiesComponent,
    ContactComponent,
    PageWorkingComponent,
    GdprComponent,
    CookiesEuComponent
  ]
})
export class LayoutModule { }
