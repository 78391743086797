import { Component } from '@angular/core';

@Component({
  selector: 'app-solar-company',
  templateUrl: './solar-company.component.html',
  styleUrls: ['./solar-company.component.less']
})
export class SolarCompanyComponent {

}
