import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-solar-intro',
  templateUrl: './solar-intro.component.html',
  styleUrls: ['./solar-intro.component.less']
})
export class SolarIntroComponent {
  constructor(private titleService: Title, private metaTagService: Meta){
  }

  ngOnInit(): void {
    this.metaTagService.addTags([
      { name: 'title', content: 'Test' },
      { name: 'keywords', content: 'fotovoltika, fotovoltaika, system, solarne systemy, fotovolticke systemy, solar, návrh a montaz fotovoltických panelov, tepelne cerpadlo, cerpadla, klimatizacia, lacne, autodiely, auto' },
      { name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' }
      ]);
    this.titleService.setTitle("Fotovoltické panely");
  }
}
