import { Component } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent {
  offerShow = true;

  closeOffer() {
    this.offerShow = false;
  }
}
