import { Component } from '@angular/core';
import { Subject } from 'src/app/models/enums';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-solar-house',
  templateUrl: './solar-house.component.html',
  styleUrls: ['./solar-house.component.less']
})
export class SolarHouseComponent {
  constructor(public sharedService: SharedServiceService<Subject>){}

  setGlobalVar(){
    this.sharedService.setGlobalVar(Subject.Fotovoltika);
  }
}
