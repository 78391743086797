import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { Gallery } from 'src/app/models/galleryModel';
import { GalleryRepositoryService } from 'src/app/services/gallery-repository.service';
declare function gallery(id: string): any;
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent {
  private _type = 0;
  private _idCarousel = "";
  platformId: Object;
  @Input()
  get type(): number { 
    return this._type; 
  }
  set type(value: number){
    this._type = value;
  }
  @Input()
  get idCarousel(): string { 
    return this._idCarousel; 
  }
  set idCarousel(value: string){
    this._idCarousel = value;
  }

  public pictures: Gallery[] = new Array<Gallery>;
   
  selectedElement: any;

  constructor(private galleryRepository: GalleryRepositoryService, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2,
  @Inject(PLATFORM_ID) platformId: Object) {
    this.platformId = platformId;  
    const scriptElt = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElt, 'type', 'text/javascript');
    this.renderer.setAttribute(scriptElt, 'src', './assets/scripts/carousel.js');
    this.renderer.appendChild(this.document.head, scriptElt);
  }

  @ViewChildren('forGalleryInit') gallery: QueryList <any> = new QueryList<any>;
  
  @ViewChildren('forMenuInit') menu: QueryList <any> = new QueryList<any>;

  ngAfterViewInit() {
    this.gallery.changes.subscribe(t => {
      this.ngForRendered(".carousel-item");
    });
    this.menu.changes.subscribe(t => {
      this.ngForRendered("#galleryMenu");
    });
  }

  ngOnInit(): void {
    /* this.galleryRepository.getPictureByType(this._type)
    .subscribe({
      next: (response) => {
        this.pictures = response;
        gallery("#" + this._idCarousel);
      },
      error: error => {
        //console.error(error);
      }
    }); */
  }

  private ngForRendered(selector: string): void {
    const select = "#" + this._idCarousel + " " + selector;
    if (isPlatformBrowser(this.platformId)) {
      const item = $(select);
      if(item != null && item.length > 0){
        item.first().addClass('active');
      }
    }
  }
}
