<section class="ma-solar-key-area ma-section-tile">
    <div class="container">
        <div class="row text-center mt-3">
            <h2 class="text-uppercase">Fotovoltika pre firmy</h2>
            <span class="text-lighter">Celkové riešenie pre Vašu firmu</span>
        </div>
        <div class="row mt-4 justify-content-center ma-attribute shadow">
            <div class="col-md-4 border-end h-100">
                <div class="">
                <p>Fotovoltika pre firmy je spôsob, ako znížiť náklady na energiu a zároveň prispieť k ochrane životného prostredia. 
                    Fotovoltické systémy využívajú slnečné žiarenie na výrobu elektriny, ktorú môžu firmy používať na svoje potreby alebo predávať do siete. 
                    Fotovoltika prináša viacero výhod.</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                <ul>
                    <li>zníženie emisií skleníkových plynov a zlepšenie ekologickej stopy firmy</li>
                    <li>zvýšenie energetickej nezávislosti</li>
                    <li>zníženie nákladov na energiu a zvýšenie konkurencieschopnosti firmy</li>
                    <li>zvýšenie hodnoty nehnuteľností a atraktivity firmy pre zákazníkov a zamestnancov</li>
                </ul>
                </div>
            </div>
        </div>
        <div class="row mt-4 h-100">
            <div class="col-md-3">
                <div class="ma-content-offer card-flip justify-content-center align-items-center d-flex">
                    <span>Výkon 20 kWp</span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="ma-content-offer card-flip justify-content-center align-items-center d-flex">
                    <span>Výkon 40 kWp</span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="ma-content-offer card-flip justify-content-center align-items-center d-flex">
                    <span>Výkon 60 kWp</span>
                </div>
            </div>
            <div class="col-md-3">
                <div class="ma-content-offer card-flip justify-content-center align-items-center d-flex">
                    <span>Výkon 80 kWp</span>
                </div>
            </div>
        </div> 
        <div class="row mt-4 justify-content-between">
            <div class="col-md-6 p-0">
                <div class="ma-attribute shadow">
                    <p>Pre firmy je to investícia do budúcnosti, ktorá sa oplatí nielen finančne, ale aj spoločensky. 
                        Vieme poskytnúť aj iné výkony fotovoltických systémov, preto nás neváhajte kontaktovať a spoločne navrhneme ideálny systém pre Váš biznis.
                        Ak máte záujem o fotovoltiku pre vašu firmu, kontaktujte nás a my Vám poradíme, aký systém je pre Vás najvhodnejší.
                    </p>
                </div>
            </div>
            <div class="col-md-6 text-center">
                <img src="../../../assets/images/solar/solar-cmp.webp" alt="Fotovoltické panely na streche výrobnej haly" class="img-thumbnail ma-image shadow">
            </div>
        </div>
        <!-- <div class="row mt-4">
            <div class="col info">
                <p>*výkonnosť, počet a typ panelov a invertorov sa môže meniť podľa požiadaviek zákazníka alebo dostupnosti tovaru</p>
            </div>
        </div> -->
    </div>
</section>