<section class="ma_breadcrumb_area">
    <div class="bg-parallax" [ngStyle]="{'background-image': 'url(' + background + ')'}" ></div>
    <div class="container">
        <div class="row text-center mt-4 text-light">
            <h2>{{title}}</h2>
            <nav class="text-center filter" style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);" aria-label="breadcrumb">
                <ol class="breadcrumb">             
                    <li *ngFor="let breadcrumb of breadcrumbs$ | async; let i = index" class="breadcrumb-item">
                      <a aria-label="Späť o úroveň" [routerLink]="[breadcrumb.url]">{{ breadcrumb.displayName }}</a>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</section>