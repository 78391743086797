import { Component } from '@angular/core';

@Component({
  selector: 'app-zaffo',
  templateUrl: './zaffo.component.html',
  styleUrls: ['./zaffo.component.less']
})
export class ZaffoComponent {

}
